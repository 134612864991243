import {
  useParams,
  useNavigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import AccountingClientLoader from "./accountingClientLoader";
import { TabView, TabPanel } from "primereact/tabview";
import Documents from "./documents/documents";
import AccountsOverview from "./accounts-overview/page";
import BasicInformation from "./basic-information/basic-information";
import styles from "./page.module.scss";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import BankInformation from "./bank-information/bank-information";

const GERMAN_TRANSLATIONS = {
  basicInformation: "Grundinformation",
  companyInformation: "Firmeninformation",
  documents: "Dokumente",
  bankAccounts: "Bankkonten",
  taxInformation: "Steuerinformation",
  accountsOverview: "Kontenübersicht",
};

const ENGLISH_TRANSLATIONS = {
  basicInformation: "Basic Information",
  companyInformation: "Company Information",
  documents: "Documents",
  bankAccounts: "Bank Accounts",
  taxInformation: "Tax Information",
  accountsOverview: "Accounts Overview",
};

function Page() {
  const { accountingClientId } = useParams<{ accountingClientId?: string }>();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AccountingClientLoader accountingClientId={accountingClientId}>
      {(props) => {
        const documentsTabPath = `/accountant/accounting-clients/${accountingClientId}/edit/documents`;

        const tabPaths = (() => {
          const basePath = accountingClientId
            ? `/accountant/accounting-clients/${accountingClientId}/edit`
            : "/accountant/accounting-clients/create";

          return [
            basePath,
            `${basePath}/documents`,
            `${basePath}/bank-accounts`,
            `${basePath}/accounts-overview`,
          ];
        })();

        const routes = (
          <Routes>
            <Route
              index
              element={
                <BasicInformation
                  formOptions={props.formOptions}
                  accountingClient={props.accountingClient}
                  setAccountingClient={props.setAccountingClient}
                />
              }
            />
            <Route
              path="documents"
              element={
                props.accountingClient ? (
                  <Documents
                    accountingClient={props.accountingClient}
                    setAccountingClient={props.setAccountingClient}
                  />
                ) : null
              }
            />
            <Route
              path="bank-accounts"
              element={
                props.accountingClient ? (
                  <BankInformation
                    accountingClient={props.accountingClient}
                    switchToDocumentsTab={() => navigate(documentsTabPath)}
                  />
                ) : null
              }
            />
            <Route
              path="accounts-overview"
              element={
                props.accountingClient ? (
                  <AccountsOverview accountingClient={props.accountingClient} />
                ) : null
              }
            />
          </Routes>
        );

        return (
          <TabView
            className={styles.tabView}
            activeIndex={tabPaths.findIndex(
              (path) => location.pathname === path
            )}
            onTabChange={({ index }) => {
              const path = tabPaths[index];
              if (!path) {
                throw new Error();
              }
              navigate(path);
            }}
          >
            <TabPanel header={translations.basicInformation}>{routes}</TabPanel>
            <TabPanel
              header={translations.documents}
              disabled={!accountingClientId}
            >
              {routes}
            </TabPanel>
            <TabPanel
              header={translations.bankAccounts}
              disabled={!accountingClientId}
            >
              {routes}
            </TabPanel>
            <TabPanel
              header={translations.accountsOverview}
              disabled={!accountingClientId}
            >
              {routes}
            </TabPanel>
          </TabView>
        );
      }}
    </AccountingClientLoader>
  );
}

export default Page;
