import { ReactNode, createContext, useState } from "react";

export type SortColumn = {
  columnKey: "created_at" | "amount" | "merchant_name";
  direction: "ASC" | "DESC";
};

function useContextValue() {
  const [sortColumn, setSortColumn] = useState<SortColumn | undefined>();

  return {
    sortColumn,
    setSortColumn,
  };
}

export const BankTransactionsPageSortingContext = createContext<
  ReturnType<typeof useContextValue> | undefined
>(undefined);

export function BankTransactionsPageSortingProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useContextValue();

  return (
    <BankTransactionsPageSortingContext.Provider value={value}>
      {children}
    </BankTransactionsPageSortingContext.Provider>
  );
}
