import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { CommunicationError } from "../../communication-errors/communication-errors";

type ClientSession = {
  user_id: string;
};

type ClientSessionState = {
  loading?: boolean;
  error?: CommunicationError;
  data?: null | ClientSession;
};

type ClientAuthenticationStateContextValue = {
  sessionState: ClientSessionState;
  setSessionState: Dispatch<SetStateAction<ClientSessionState>>;
};

export const ClientAuthenticationStateContext = createContext<
  ClientAuthenticationStateContextValue | undefined
>(undefined);

export function ClientAuthenticationStateProvider(props: {
  children: React.ReactNode;
}) {
  const [sessionState, setSessionState] = useState<ClientSessionState>({});

  const value = useMemo(() => {
    return {
      sessionState,
      setSessionState,
    };
  }, [sessionState]);

  return (
    <ClientAuthenticationStateContext.Provider value={value}>
      {props.children}
    </ClientAuthenticationStateContext.Provider>
  );
}
