import { DetailsPanel } from "./details-panel/details-panel";
import { Results } from "./results/results";
import { ResultsContextProvider } from "./results/results-context";
import { FiltersContextProvider } from "./top-section/navbar/filters/filters-context";
import { QuickFiltersContextProvider } from "./top-section/navbar/quick-filters/quick-filters-context";
import { TopSection } from "./top-section/top-section";
import styles from "./page.module.scss";
import { ReactNode } from "react";
import { SortingContextProvider } from "./sorting/sorting-context";
import { BookingsPageBankAccountsProvider } from "./top-section/navbar/quick-filters/bank-accounts-context";
import { BookingsPageResourcesLoader } from "./page-resources";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export function BookingsPage() {
  return (
    <>
      <BookingsPageResourcesLoader>
        <BookingsPageBankAccountsProvider>
          <QuickFiltersContextProvider>
            <FiltersContextProvider>
              <SortingContextProvider>
                <ResultsContextProvider>
                  <Page>
                    <TopSection />
                    <Results />
                    <DetailsPanel />
                  </Page>
                </ResultsContextProvider>
              </SortingContextProvider>
            </FiltersContextProvider>
          </QuickFiltersContextProvider>
        </BookingsPageBankAccountsProvider>
      </BookingsPageResourcesLoader>
    </>
  );
}
