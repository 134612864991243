import { z } from "zod";
import { AccountingClient } from "../../../../accountingClientLoader";
import { useMainApi } from "../../../../../../../../../main-api";
import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import { CommunicationError } from "../../../../../../../../../communication-errors/communication-errors";
import {
  BankAccountsFiltersContext,
  Filters,
} from "../toolbar/filters-context";
import {
  BankAccountsSortingContext,
  SortColumn,
} from "./sorting/sorting-context";
import { throwError } from "../../../../../../../../../throw-error";

export const BankAccountSchema = z.object({
  id: z.string().uuid(),
  iban: z.string().nullable(),
  account_id: z.string().uuid(),
  account_number: z.number(),
  account_name: z.string(),
  total_transactions: z.number(),
});

export type BankAccount = z.TypeOf<typeof BankAccountSchema>;

type RequestedParams = {
  offset: number;
  filters: Filters;
  sortColumn: SortColumn;
  accountingClientId: string;
};

function useContextValue(accountingClient: AccountingClient) {
  const mainApi = useMainApi();

  const accountingClientId = accountingClient.id;

  const { sortColumn } = useContext(BankAccountsSortingContext) || throwError();

  const { filters } = useContext(BankAccountsFiltersContext) || throwError();

  const [offset, setOffset] = useState(0);

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        total: number;
        rows: BankAccount[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [lastRequestParams, setLastRequestParams] = useState<
    RequestedParams | undefined
  >();

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }

      const requestParams: RequestedParams = {
        offset,
        accountingClientId,
        sortColumn,
        filters,
      };

      if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
        return;
      }

      setLastRequestParams(requestParams);

      const resetResults =
        JSON.stringify({
          accountingClientId: requestParams.accountingClientId,
          sortColumn: requestParams.sortColumn,
          filters: requestParams.filters,
        }) !==
        JSON.stringify({
          accountingClientId: lastRequestParams?.accountingClientId,
          sortColumn: lastRequestParams?.sortColumn,
          filters: lastRequestParams?.filters,
        });

      if (resetResults) {
        setResults(() => {
          return {};
        });
      }

      const _offset = resetResults ? 0 : offset;

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append("sort_by", sortColumn.columnKey);
      urlSearchParams.append("sort_order", sortColumn.direction);
      urlSearchParams.append("offset", _offset.toString());

      if (filters.search) {
        urlSearchParams.append("search", filters.search);
      }

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${accountingClientId}/accounts_overview/bank_accounts?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            total: z.number(),
            accounts: z.array(BankAccountSchema),
          }),
        }),
      });

      if (res.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: res.error,
          };
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            total: res.response.body.total,
            rows: resetResults
              ? res.response.body.accounts
              : [...(results.data?.rows || []), ...res.response.body.accounts],
          },
        };
      });
    })();
  }, [
    mainApi,
    accountingClientId,
    offset,
    results,
    sortColumn,
    filters,
    lastRequestParams,
  ]);

  return useMemo(() => {
    return {
      offset,
      setOffset,
      results,
      setResults,
      accountingClientId,
    };
  }, [offset, results, accountingClientId]);
}

export const BankAccountsResultsContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);
export function BankAccountsResultsContextProvider(props: {
  accountingClient: AccountingClient;
  children: ReactNode;
}) {
  const { accountingClient, children } = props;
  const value = useContextValue(accountingClient);

  return (
    <BankAccountsResultsContext.Provider value={value}>
      {children}
    </BankAccountsResultsContext.Provider>
  );
}
