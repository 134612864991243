import z from "zod";
import { useState } from "react";
import { useMainApi } from "../../main-api";
import { CommunicationError } from "../../communication-errors/communication-errors";
import { getCommunicationErrorMessage } from "../../communication-errors/communication-error-messages";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import styles from "./reset-password.module.scss";
import { useCurrentLanguage } from "../../language/current-language";
import { SupportedLanguage } from "../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  resetYourPassword: "Setzen Sie Ihr Passwort zurück",
  resetFlowInstruction:
    "Schicken Sie eine E-Mail mit dem Passwort-Zurücksetzungslink an Ihre E-Mail-Adresse.",
  resetPasswordEmailSent:
    "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet. Bitte überprüfen Sie Ihren Posteingang und den Spam-Ordner.",

  back: "Zurück",
};

const ENGLISH_TRANSLATIONS = {
  resetYourPassword: "Reset your password",
  resetFlowInstruction:
    "Send an email with the password reset link to your email address.",

  resetPasswordEmailSent:
    "We've sent you an email with a link to reset your password. Please check your inbox and spam folder.",
  back: "Back",
};

export function ResetPassword() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const [state, setState] = useState<{
    loading?: boolean;
    error?: undefined | CommunicationError;
    success?: boolean;
  }>({});

  const onSubmit = async () => {
    setState({ loading: true });

    const res = await mainApi.fetchJSON({
      path: `/mobile_app_users/request-password-change`,
      method: "POST",
      schema: z.object({
        status: z.literal(200),
        body: z.object({}),
      }),
    });

    setState({ loading: false });

    if (res.error) {
      setState({ error: res.error });
      return;
    }

    setState({ success: true });
  };

  return (
    <>
      <Card
        title={translations.resetYourPassword}
        footer={
          <div className={styles.actions}>
            <Button
              label={translations.resetYourPassword}
              onClick={onSubmit}
              disabled={state.loading}
              loading={state.loading}
            />
          </div>
        }
      >
        {state.error && (
          <div className={styles.marginBottom}>
            <Message
              severity="error"
              text={getCommunicationErrorMessage(state.error)}
              className={styles.width100Percent}
            />
          </div>
        )}
        {state.success && (
          <div className={styles.marginBottom}>
            <Message
              severity="success"
              text={translations.resetPasswordEmailSent}
              className={styles.width100Percent}
            />
          </div>
        )}
        <p>{translations.resetFlowInstruction}</p>
      </Card>
    </>
  );
}
