import { Column } from "react-data-grid";
import { Accountant } from "./accountants-loader";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { ReactDataGrid } from "../../../react-data-grid";

const ENGLISH_TRANSLATIONS = {
  name: "Name",
  email: "Email",
};

const GERMAN_TRANSLATIONS = {
  name: "Name",
  email: "Email",
};

export function AccountantsTable(props: {
  accountants: Accountant[];
  selectedAccountant: Accountant | undefined;
  onAccountantSelect: (accountantId: string | undefined) => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const columns: Column<Accountant>[] = [
    {
      key: "name",
      name: translations.name,
      resizable: true,
      renderCell: (args) => {
        return args.row.name;
      },
    },
    {
      key: "email",
      name: translations.email,
      resizable: true,

      renderCell: (args) => {
        return args.row.email;
      },
    },
  ];

  return (
    <ReactDataGrid
      rows={props.accountants}
      columns={columns}
      rowKeyGetter={(r) => r.id}
      onSelectedCellChange={(args) => {
        if (!args.row) {
          return;
        }

        props.onAccountantSelect(args.row.id);
      }}
    />
  );
}
