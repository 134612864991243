import { Key, useEffect, useState } from "react";
import OriginalReactDataGrid, {
  CalculatedColumn,
  DataGridProps,
} from "react-data-grid";

interface CellSelectArgs<TRow, TSummaryRow = unknown> {
  rowIdx?: number;
  row?: TRow;
  column: CalculatedColumn<TRow, TSummaryRow>;
}

export function ReactDataGrid<R, SR = unknown, K extends Key = Key>(
  props: Omit<DataGridProps<R, SR, K>, "onSelectedCellChange" | "onScroll"> & {
    onWheelAndBottom?: () => void;
    onSelectedCellChange?: (
      args: CellSelectArgs<NoInfer<R>, NoInfer<SR>>
    ) => void;
  }
) {
  const [element, setElement] = useState<HTMLElement | null | undefined>();

  const onWheelAndBottom = props.onWheelAndBottom;

  useEffect(() => {
    if (element) {
      const listener = (e: WheelEvent) => {
        const target = e.currentTarget;

        if (!target || !(target instanceof HTMLElement)) {
          throw new Error();
        }

        const hasScroll = target.scrollHeight > target.clientHeight;

        const isBottom =
          target.scrollHeight - target.scrollTop <= target.clientHeight + 10;

        if (hasScroll && !isBottom) {
          return;
        }

        onWheelAndBottom?.();
      };

      element.addEventListener("wheel", listener);

      return () => {
        element.removeEventListener("wheel", listener);
      };
    }
  }, [element, onWheelAndBottom]);

  return (
    <OriginalReactDataGrid
      {...props}
      className={`${props.className || ""} rdg-light`}
      ref={(ref) => {
        setElement(ref?.element);
      }}
    />
  );
}
