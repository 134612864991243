import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import styles from "./filters.module.scss";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { OverlayPanel } from "primereact/overlaypanel";
import { useContext, useEffect, useRef } from "react";
import {
  Filters as FiltersType,
  FiltersContext,
  FiltersSchema,
} from "./filters-context";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { throwError } from "../../../../../../../../throw-error";
import { BookingsPageResourcesContext } from "../../../page-resources";
import { Checkbox } from "primereact/checkbox";

const GERMAN_TRANSLATIONS = {
  all: "Alle",
  debits: "Soll",
  credits: "Haben",
  amount: "Betrag",
  counterAccount: "Gegenseite",
  status: "Status",
  min: "Min",
  max: "Max",
  processed: "Verarbeitet",
  confirmed: "Bestätigung erforderlich",
  action: "Aktion erforderlich",
  historical: "Historisch",
  solved: "Vom Benutzer gelöst",
  searchButton: "Suchen",
  filtersTooltip: "Filter",
  unableToPredict: "Keine verlässliche Vorhersage möglich",
};

const ENGLISH_TRANSLATIONS = {
  all: "All",
  debits: "Debit",
  credits: "Credit",
  amount: "Amount",
  counterAccount: "Counter Account",
  status: "Status",
  min: "Min",
  max: "Max",
  processed: "Processed",
  confirmed: "Confirmation Needed",
  historical: "Historical",
  action: "Action Needed",
  solved: "Solved by User",
  searchButton: "Search",
  filtersTooltip: "Filters",
  unableToPredict: "Unable to make a reliable prediction",
};

function FiltersPanel() {
  const { filters, setFilters } = useContext(FiltersContext) || throwError();
  const { counter_accounts } =
    useContext(BookingsPageResourcesContext) || throwError();

  const form = useForm<FiltersType>({
    resolver: zodResolver(FiltersSchema),
    mode: "onChange",
    defaultValues: filters,
  });
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  useEffect(() => {
    form.reset(filters);
  }, [filters, form]);

  const onSubmit = (values: FiltersType) => {
    setFilters(values);
  };

  return (
    <form
      className={styles.filters}
      noValidate
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className={styles.filter}>
        <b>{translations.amount}</b>
        <Controller
          name="amount_min"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <label className="p-inputgroup-addon" htmlFor="amount-min">
                  <small>{translations.min}</small>
                </label>
                <InputNumber
                  className={styles.input}
                  id="amount-min"
                  value={field.value}
                  onValueChange={(e) => field.onChange(e.target.value)}
                  locale={navigator.language}
                  maxFractionDigits={2}
                />
              </div>
            );
          }}
        />
        <Controller
          name="amount_max"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <label className="p-inputgroup-addon" htmlFor="amount-max">
                  <small>{translations.max}</small>
                </label>
                <InputNumber
                  className={styles.input}
                  id="amount-max"
                  value={field.value}
                  onValueChange={(e) => field.onChange(e.target.value)}
                  locale={navigator.language}
                  maxFractionDigits={2}
                />
              </div>
            );
          }}
        />
      </div>

      <div className={styles.debit}>
        <Controller
          name="is_debit"
          control={form.control}
          render={({ field }) => (
            <div className={styles.optionsGroup}>
              <div className={styles.option}>
                <RadioButton
                  inputId="bookings-filters-is-debit-all"
                  value={null}
                  checked={field.value === null}
                  onChange={() => field.onChange(null)}
                />
                <label htmlFor={"bookings-filters-is-debit-all"}>
                  {translations.all}
                </label>
              </div>
              <div className={styles.option}>
                <RadioButton
                  inputId="bookings-filters-is-debit-true"
                  onChange={() => field.onChange(true)}
                  value={true}
                  checked={field.value === true}
                />
                <label htmlFor={"bookings-filters-is-debit-true"}>
                  {translations.debits}
                </label>
              </div>
              <div className={styles.option}>
                <RadioButton
                  inputId="bookings-filters-is-debit-false"
                  onChange={() => field.onChange(false)}
                  value={false}
                  checked={field.value === false}
                />
                <label htmlFor={"bookings-filters-is-debit-false"}>
                  {translations.credits}
                </label>
              </div>
            </div>
          )}
        />
      </div>

      <div className={styles.filter}>
        <b>{translations.counterAccount}</b>
        <Controller
          name="counter_account"
          control={form.control}
          render={({ field }) => {
            return (
              <>
                <div className={`${styles.inputGroup}`}>
                  <Dropdown
                    className={styles.input}
                    id={field.name}
                    showClear
                    options={[
                      ...(counter_accounts.map((account) => ({
                        label: `${account.number} - ${account.name}`,
                        value: account.id,
                      })) ?? []),
                    ]}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value || null)}
                  />
                </div>
              </>
            );
          }}
        />
      </div>

      <div className={styles.status}>
        <b>{translations.status}</b>
        <Controller
          name="status"
          control={form.control}
          render={({ field }) => (
            <div className={styles.optionsGroup}>
              <div className={styles.option}>
                <Checkbox
                  inputId="bookings-filters-status-historical"
                  value="historical"
                  onChange={(e) => {
                    if (e.checked) {
                      field.onChange([...field.value, "historical"]);
                    } else {
                      field.onChange(
                        field.value.filter((s) => s !== "historical")
                      );
                    }
                  }}
                  checked={field.value.includes("historical")}
                />
                <label htmlFor="bookings-filters-status-historical">
                  <Tag
                    className={styles.historicalStatusBadge}
                    severity="success"
                    value={translations.historical}
                  />
                </label>
              </div>

              <div className={styles.option}>
                <Checkbox
                  inputId="bookings-filters-status-action"
                  value="action"
                  onChange={(e) => {
                    if (e.checked) {
                      field.onChange([...field.value, "action"]);
                    } else {
                      field.onChange(field.value.filter((s) => s !== "action"));
                    }
                  }}
                  checked={field.value.includes("action")}
                />
                <label htmlFor="bookings-filters-status-action">
                  <Tag severity="danger" value={translations.action} />
                </label>
              </div>

              <div className={styles.option}>
                <Checkbox
                  inputId="bookings-filters-status-corrected"
                  value="corrected"
                  onChange={(e) => {
                    if (e.checked) {
                      field.onChange([...field.value, "corrected"]);
                    } else {
                      field.onChange(
                        field.value.filter((s) => s !== "corrected")
                      );
                    }
                  }}
                  checked={field.value.includes("corrected")}
                />
                <label htmlFor="bookings-filters-status-corrected">
                  <Tag severity="info" value={translations.solved} />
                </label>
              </div>

              <div className={styles.option}>
                <Checkbox
                  inputId="bookings-filters-status-predicted"
                  value="predicted"
                  onChange={(e) => {
                    if (e.checked) {
                      field.onChange([...field.value, "predicted"]);
                    } else {
                      field.onChange(
                        field.value.filter((s) => s !== "predicted")
                      );
                    }
                  }}
                  checked={field.value.includes("predicted")}
                />
                <label htmlFor="bookings-filters-status-predicted">
                  <Tag severity="success" value={translations.processed} />
                </label>
              </div>
            </div>
          )}
        />
      </div>

      <Button
        type="submit"
        className={styles.submit}
        icon="pi pi-search"
        label={translations.searchButton}
      />
    </form>
  );
}

export function Filters() {
  const filtersOverlayPanel = useRef<null | OverlayPanel>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Button
        icon="pi pi-sliders-h"
        onClick={(e) => filtersOverlayPanel.current?.toggle(e)}
        tooltip={translations.filtersTooltip}
      />
      <OverlayPanel
        className={styles.filtersOverlayPanel}
        ref={filtersOverlayPanel}
      >
        <FiltersPanel />
      </OverlayPanel>
    </>
  );
}
