import styles from "./page.module.scss";
import { ResetPassword } from "./reset-password";

export function AccountantUserSettings() {
  return (
    <div className={styles.page}>
      <ResetPassword />
    </div>
  );
}
