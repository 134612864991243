import { useState, useRef } from "react";
import z from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import validator from "validator";
import { useMainApi } from "../../../../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../communication-errors/communication-error-messages";
import {
  AccountingClientBankAccountsSchema,
  AccountingClientBankAccounts,
} from "../bank-information";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import styles from "./add-new-bank-account-with-iban.module.scss";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  ibanInstruction:
    "Verbinden Sie ein neues Bankkonto, indem Sie die IBAN-Nummer eingeben. Sie können anschließend MT940-Dateien für dieses Bankkonto hochladen.",
  ibanLabel: "IBAN-Nummer",
  ibanExistsWarning: "IBAN existiert bereits",
  ibanExistsWarningDetail: "IBAN existiert bereits",
  addBankAccountButton: "Bankkonto hinzufügen",
  success: "Erfolg",
  successDetail: "Bankkonto erfolgreich erstellt",
  pleaseEnterAValidIban: "Bitte geben Sie eine gültige IBAN ein",
};

const ENGLISH_TRANSLATIONS = {
  ibanInstruction:
    "Connect a new bank account by inserting the IBAN number. You will be able to upload MT940 files for this bank account afterwards.",
  ibanLabel: "IBAN number",
  ibanExistsWarning: "IBAN already exists",
  ibanExistsWarningDetail: "IBAN already exists",
  addBankAccountButton: "Add Bank Account",
  success: "Success",
  successDetail: "Bank account created successfully",
  pleaseEnterAValidIban: "Please enter a valid IBAN",
};

const NewBankAccountWithIBANFormSchema = () => {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return z.object({
    iban: z.string().refine((value) => validator.isIBAN(value), {
      message: translations.pleaseEnterAValidIban,
    }),
  });
};

type NewBankAccountWithIBANFormValue = z.infer<
  ReturnType<typeof NewBankAccountWithIBANFormSchema>
>;

export function AddBankAccountWithIBAN({
  accountingClientId,
  setResults,
}: {
  accountingClientId: string;
  setResults: (results: AccountingClientBankAccounts) => void;
}) {
  const mainApi = useMainApi();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastRef = useRef<Toast>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const form = useForm<NewBankAccountWithIBANFormValue>({
    resolver: zodResolver(NewBankAccountWithIBANFormSchema()),
    defaultValues: {
      iban: "",
    },
  });

  const onSubmit = async (formData: NewBankAccountWithIBANFormValue) => {
    setIsSubmitting(true);

    const res = await mainApi.fetchJSON({
      path: `/accounting_clients/${accountingClientId}/bank_accounts/create_bank_account_with_iban/`,
      method: "POST",
      body: {
        iban: formData.iban,
      },
      schema: z.union([
        z.object({
          status: z.literal(409),
          body: z.object({
            detail: z.literal("iban-already-exists"),
          }),
        }),
        z.object({
          status: z.literal(200),
          body: AccountingClientBankAccountsSchema,
        }),
      ]),
    });

    setIsSubmitting(false);

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error),
        detail: getCommunicationErrorMessage(res.error),
      });

      return;
    }

    if (res.response.status === 409) {
      toastRef.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: translations.ibanExistsWarningDetail,
      });

      return;
    }

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successDetail,
    });

    setResults(res.response.body);
    form.reset({ iban: "" });
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.formField}>
          <Toast ref={toastRef} />
          <span>{translations.ibanInstruction}</span>
          <div className={styles.inputGroup}>
            <div className={styles.inputLabel}>{translations.ibanLabel}</div>
            <div className={styles.inputField}>
              <Controller
                name="iban"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputText
                    {...field}
                    className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                )}
              />
              {form.formState.errors.iban && (
                <small className={styles.errorMessage}>
                  {form.formState.errors.iban.message}
                </small>
              )}
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {translations.addBankAccountButton}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
