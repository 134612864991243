import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { getCommunicationErrorMessage } from "../../../../communication-errors/communication-error-messages";
import { Column } from "react-data-grid";
import { SortColumn } from "react-data-grid";
import { throwError } from "../../../../throw-error";
import { CommunicationError } from "../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../main-api";
import styles from "./clientsPage.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { Badge } from "primereact/badge";
import "primeicons/primeicons.css";
import { z } from "zod";
import { Filters } from "./clientsFilter";
import { useNavigate } from "react-router-dom";
import { AccountantAuthenticationStateContext } from "../../../../accountants/authentication/authentication-state";
import { BANK_TRANSACTIONS_ROUTES } from "../../accounting-clients/accounting-client-id/bank-transactions/bank-transactions-routes";
import { EDIT_CLIENTS_ROUTES } from "../../accounting-clients/accounting-client-id/edit-clients/edit-clients-routes";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { CreateClientWithGdpduFile } from "./create-client-with-gdpdu-file";
import { ReactDataGrid } from "../../../../react-data-grid";

const AccountingClientSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  client_number: z.string().nullable(),
  total_issues: z.number(),
});

const GERMAN_TRANSLATIONS = {
  name: "Name",
  clients: "Kunden",
  clientNumber: "Kundennummer",
  issues: "Probleme",
  createNewManually: "Manuell erstellen",
  filters: "Filter",
  refresh: "Aktualisieren",
  goTo: "Gehe zu",
  editClient: "Kunden bearbeiten",
  invoices: "Rechnungen",
  bankTransactions: "Banktransaktionen",
  accountantSettings: "Buchhaltungseinstellungen",
};

const ENGLISH_TRANSLATIONS = {
  name: "Name",
  clients: "Clients",
  clientNumber: "Client Number",
  issues: "Issues",
  createNewManually: "Create new manually",
  filters: "Filters",
  refresh: "Refresh",
  goTo: "Go to",
  editClient: "Edit Client",
  invoices: "Invoices",
  bankTransactions: "Bank Transactions",
  accountantSettings: "Accountant Settings",
};

type AccountingClientsResultsState = {
  loading?: boolean;
  error?: CommunicationError;
  data?: Array<z.TypeOf<typeof AccountingClientSchema>>;
};

type ContextMenuProps = {
  model: MenuItem[];
  menuRef: React.RefObject<Menu>;
};

const ContextMenu = ({ model, menuRef }: ContextMenuProps) => {
  return <Menu model={model} popup ref={menuRef} popupAlignment="right" />;
};

function _Page() {
  const mainApi = useMainApi();

  const accountantAuthenticationStateContext =
    useContext(AccountantAuthenticationStateContext) || throwError();

  const session =
    accountantAuthenticationStateContext.sessionState.data || throwError();

  const [results, setResults] = useState<AccountingClientsResultsState>({});

  const [filters, setFilters] = useState<{
    name: string | null;
    client_number: string | null;
  }>({ name: "", client_number: "" });

  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

  const menuRef = useRef<Menu>(null);

  const navigate = useNavigate();

  const handleCreateNewClick = () => {
    const href = EDIT_CLIENTS_ROUTES.create.getHref();
    navigate(href);
  };

  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);

  const filtersOverlayPanel = useRef<null | OverlayPanel>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const fetchClients = useCallback(async () => {
    setResults({ loading: true });

    const res = await mainApi.fetchJSON({
      method: "GET",
      path: "/accounting_clients",
      schema: z.object({
        status: z.literal(200),
        body: z.array(AccountingClientSchema),
      }),
    });

    if (res.error) {
      setResults({ error: res.error });
      return;
    }

    const filteredData = res.response.body.filter((client) => {
      const matchesName = filters.name
        ? client.name?.toLowerCase().includes(filters.name.toLowerCase())
        : true;
      const matchesClientNumber = filters.client_number
        ? client.client_number
            ?.toLowerCase()
            .includes(filters.client_number.toLowerCase())
        : true;

      return matchesName && matchesClientNumber;
    });

    setResults({ data: filteredData });
  }, [mainApi, filters]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const getRowKey = useCallback(
    (r: Partial<z.TypeOf<typeof AccountingClientSchema>>) =>
      r.id || throwError(),
    []
  );

  const handleSearch = useCallback(
    (newFilters: { name: string | null; client_number: string | null }) => {
      setFilters(newFilters);
    },
    []
  );

  const columns: Column<z.TypeOf<typeof AccountingClientSchema>>[] =
    useMemo(() => {
      return [
        {
          key: "name",
          name: translations.name,
          renderCell: (args) => {
            return args.row.name || "";
          },
        },

        {
          key: "client_number",
          name: translations.clientNumber,
          width: 250,
          renderCell: (args) => {
            return args.row.client_number || "";
          },
        },
        {
          key: "number_of_issues",
          name: translations.issues,
          width: 90,
          renderCell: ({ row }) => {
            return (
              <div className={styles.textAlignCenter}>
                <Badge value={row.total_issues} />
              </div>
            );
          },
        },
      ];
    }, [translations.clientNumber, translations.issues, translations.name]);

  return (
    <div className={styles.page}>
      {(() => {
        return (
          <>
            <div className={styles.pageHeader}>
              <h2 className={styles.title}>{translations.clients}</h2>
            </div>

            <div className={styles.buttonBar}>
              <div className={styles.buttonGroup}>
                <CreateClientWithGdpduFile fetchClients={fetchClients} />
                <Button
                  label={translations.createNewManually}
                  icon="pi pi-plus"
                  severity="secondary"
                  onClick={handleCreateNewClick}
                  disabled={!session.permissions.can_add_clients}
                />
                <Button
                  icon="pi pi-refresh"
                  severity="secondary"
                  onClick={fetchClients}
                />
              </div>
              <div className={styles.buttonGroup}>
                <Button
                  label={translations.filters}
                  severity={"secondary"}
                  onClick={(e) => filtersOverlayPanel.current?.toggle(e)}
                />
                <OverlayPanel
                  className={styles.filtersOverlayPanel}
                  ref={filtersOverlayPanel}
                >
                  <Filters onSearch={handleSearch} />
                </OverlayPanel>
              </div>
            </div>
            {results.loading && <ProgressSpinner />}
            {results.error && (
              <div>{getCommunicationErrorMessage(results.error)}</div>
            )}
            {results.data ? (
              <>
                <ReactDataGrid
                  className={`${styles.table}`}
                  rows={results.data}
                  columns={columns}
                  rowKeyGetter={getRowKey}
                  sortColumns={sortColumns}
                  onSortColumnsChange={setSortColumns}
                  onCellClick={(args, event) => {
                    const clientId = args.row.id;
                    menuRef.current?.toggle(event);
                    setSelectedClientId(clientId);
                  }}
                />

                <ContextMenu
                  model={[
                    {
                      label: translations.goTo,
                      items: [
                        {
                          label: translations.editClient,
                          command: () =>
                            selectedClientId &&
                            navigate(
                              EDIT_CLIENTS_ROUTES.edit.getHref({
                                accountingClientId: selectedClientId,
                              })
                            ),
                        },
                        // {
                        //   label: translations.invoices,
                        //   command: () =>
                        //     selectedClientId &&
                        //     navigate(
                        //       INVOICES_ROUTES.getHref({
                        //         accountingClientId: selectedClientId,
                        //       })
                        //     ),
                        // },
                        {
                          label: translations.bankTransactions,
                          command: () =>
                            selectedClientId &&
                            navigate(
                              BANK_TRANSACTIONS_ROUTES.getHref({
                                accountingClientId: selectedClientId,
                              })
                            ),
                        },
                      ],
                    },
                  ]}
                  menuRef={menuRef}
                />
              </>
            ) : null}
          </>
        );
      })()}
    </div>
  );
}

export function Clients() {
  return <_Page />;
}
