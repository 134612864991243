import { Controller, UseFormReturn } from "react-hook-form";
import formSections from "./form-sections.module.scss";
import { AccountingClientFormValue } from "../basic-information";
import { InputText } from "primereact/inputtext";
import { AccountingClient } from "../../accountingClientLoader";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  contactInformation: "Kontaktinformationen",
  email: "E-Mail",
  phoneNumber: "Telefonnummer",
  contactAddressSameAsClient: "Kontaktadresse entspricht der Kundenadresse",
  preferredContactMethod: "Bevorzugte Kontaktmethode",
  emailOption: "E-Mail",
  phoneOption: "Telefon",
};

const ENGLISH_TRANSLATIONS = {
  contactInformation: "Contact information",
  email: "Email",
  phoneNumber: "Phone number",
  contactAddressSameAsClient: "Contact address same as client address",
  preferredContactMethod: "Preferred contact method",
  emailOption: "Email",
  phoneOption: "Phone",
};

export function ContactInformation(props: {
  form: UseFormReturn<AccountingClientFormValue>;
  accountingClient: AccountingClient | null;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <h2 className={formSections.title}>{translations.contactInformation}</h2>

      <div className={formSections.formField}>
        <Controller
          name="email"
          control={props.form.control}
          render={({ field, fieldState }) => {
            return (
              <>
                <label className={formSections.label} htmlFor={field.name}>
                  {translations.email}
                </label>
                <div className={`${formSections.inputWrapper}`}>
                  <InputText
                    className={`${formSections.input} ${
                      fieldState.error ? "p-invalid" : ""
                    }`}
                    id={field.name}
                    value={field.value || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value || null);
                    }}
                  />
                  {fieldState.error && (
                    <small className={formSections.errorMessage}>
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
}
