"use client";
import { useMemo, useContext, useRef, useCallback } from "react";
import styles from "./page.module.scss";
import { Filters } from "./filters/filters";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../../communication-errors/communication-error-messages";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { throwError } from "../../../../../throw-error";
import {
  BankTransaction,
  BankTransactionsPageResultsContext,
  BankTransactionsPageResultsProvider,
} from "./results/results-context";
import {
  BankTransactionsPageSortingContext,
  BankTransactionsPageSortingProvider,
  SortColumn,
} from "./sorting/sorting-context";
import { BankTransactionsPageFiltersProvider } from "./filters/filters-context";
import { BankAccountsDropdown } from "./bank-accounts/bank-accounts-dropdown";
import { BankTransactionsBankAccountsProvider } from "./bank-accounts/bank-accounts-context";
import { UploadMt940Button } from "./upload-mt940-button";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../react-data-grid";
import { BankAccountsLoader } from "./bank-accounts/bank-accounts-loader";

const GERMAN_TRANSLATIONS = {
  bankTransactions: "Banktransaktionen",
  filters: "Filter",
  account: "Konto",
  amount: "Betrag",
  date: "Datum",
  loadingMoreRows: "Weitere Zeilen werden geladen...",
};

const ENGLISH_TRANSLATIONS = {
  bankTransactions: "Bank Transactions",
  filters: "Filters",
  account: "Account",
  amount: "Amount",
  date: "Date",
  loadingMoreRows: "Loading more rows...",
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

function _BankTransactions() {
  const { results, setOffset } =
    useContext(BankTransactionsPageResultsContext) || throwError();

  const sorting =
    useContext(BankTransactionsPageSortingContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const filtersOverlayPanel = useRef<null | OverlayPanel>(null);

  const getRowKey = useCallback(
    (r: Partial<BankTransaction>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn(sortColumn as SortColumn | undefined);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return sorting.sortColumn ? [sorting.sortColumn] : [];
  }, [sorting]);

  const columns: Column<BankTransaction>[] = useMemo(() => {
    return [
      {
        key: "merchant_name",
        name: translations.account,
        sortable: true,
        renderCell: (args) => {
          return args.row.merchant_name || "";
        },
      },
      {
        key: "amount",
        name: translations.amount,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.amount;

          if (!value && value !== 0) {
            return "";
          } else {
            return `€ ${value}`;
          }
        },
        cellClass: (row) => {
          if (typeof row.amount !== "number") {
            return "";
          }

          if (row.amount <= 0) {
            return styles.hasDebit;
          } else {
            return styles.hasCredit;
          }
        },
      },
      {
        key: "created_at",
        name: translations.date,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.created_at;

          if (value) {
            return dateFormatter.format(value);
          } else {
            return "";
          }
        },
      },
    ];
  }, [translations]);

  return (
    <div className={styles.page}>
      <h2 className={styles.title}>{translations.bankTransactions}</h2>
      <div className={styles.buttonBar}>
        <div className={styles.buttonGroup}>
          <BankAccountsDropdown className={styles.dropdown} />
          <UploadMt940Button />
        </div>
        <div className={styles.buttonGroup}>
          <Button
            label={translations.filters}
            severity={"secondary"}
            onClick={(e) => filtersOverlayPanel.current?.toggle(e)}
          />
          <OverlayPanel
            className={styles.filtersOverlayPanel}
            ref={filtersOverlayPanel}
          >
            <Filters />
          </OverlayPanel>
        </div>
      </div>
      {(() => {
        const data = results.data;
        if (results.loading && !data) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (results.error) {
          return (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>
                {getCommunicationErrorMessage(results.error)}
              </p>
            </div>
          );
        }

        if (!data) {
          return;
        }

        return (
          <>
            <ReactDataGrid
              className={`${styles.table}`}
              rows={results.data.rows}
              columns={columns}
              rowKeyGetter={getRowKey}
              sortColumns={sortColumns}
              onWheelAndBottom={() => {
                setOffset(data.rows.length);
              }}
              onSortColumnsChange={onSortColumnsChange}
            />
            {results.loading && (
              <div className={styles.loadingNextPageContainer}>
                {translations.loadingMoreRows}
              </div>
            )}
          </>
        );
      })()}
    </div>
  );
}

export default function BankTransactionsPage() {
  return (
    <BankAccountsLoader>
      {({ bank_accounts }) => (
        <BankTransactionsBankAccountsProvider bank_accounts={bank_accounts}>
          <BankTransactionsPageFiltersProvider>
            <BankTransactionsPageSortingProvider>
              <BankTransactionsPageResultsProvider>
                <_BankTransactions />
              </BankTransactionsPageResultsProvider>
            </BankTransactionsPageSortingProvider>
          </BankTransactionsPageFiltersProvider>
        </BankTransactionsBankAccountsProvider>
      )}
    </BankAccountsLoader>
  );
}
