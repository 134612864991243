import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import styles from "./clientsPage.module.scss";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";

const FiltersSchema = z.object({
  name: z.string().nullable(),
  client_number: z.string().nullable(),
});

const GERMAN_TRANSLATIONS = {
  filters: "Filter",
  name: "Name",
  clientNumber: "Kundennummer",
  search: "Suchen",
};

const ENGLISH_TRANSLATIONS = {
  filters: "Filters",
  name: "Name",
  clientNumber: "Client Number",
  search: "Search",
};

type FiltersProps = {
  onSearch: (filters: z.TypeOf<typeof FiltersSchema>) => void;
};

export function Filters({ onSearch }: FiltersProps) {
  const form = useForm({
    resolver: zodResolver(FiltersSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      client_number: "",
    },
  });

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <form
      onSubmit={form.handleSubmit((values) => {
        onSearch(values);
      })}
      className={styles.filtersForm}
    >
      <h3 className={styles.filterPanelTitle}>{translations.filters}</h3>
      <div className={styles.filter}>
        <h4 className={styles.filterLabel}>{translations.name}</h4>
        <InputText {...form.register("name")} style={{ width: "100%" }} />
      </div>
      <div className={styles.filter}>
        <h4 className={styles.filterLabel}>{translations.clientNumber}</h4>
        <InputText
          {...form.register("client_number")}
          style={{ width: "100%" }}
        />
      </div>
      <Button
        type="submit"
        className={styles.submitButton}
        icon="pi pi-search"
        label={translations.search}
      />
    </form>
  );
}
