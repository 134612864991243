import { useCallback } from "react";
import { useCurrentLanguage } from "../language/current-language";
import { SupportedLanguage } from "../language/supported-languages";
import { CommunicationError } from "./communication-errors";

const GERMAN_TRANSLATIONS = {
  title: {
    [CommunicationError.ConnectionFailure]: "Verbindungsfehler",
    [CommunicationError.UnexpectedResponse]: "Unerwartete Antwort",
    [CommunicationError.AbortedAndDealtWith]: "Weiterleitung...",
  },
  message: {
    [CommunicationError.ConnectionFailure]:
      "Wir sind mit dem Internet verbindungslos. Bitte prüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.",
    [CommunicationError.UnexpectedResponse]:
      "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut, und wenn das Problem behoben ist, kontaktieren Sie uns.",
    [CommunicationError.AbortedAndDealtWith]:
      "Weiterleitung abgebrochen, da es sich um eine Abbruchbedingung handelt.",
  },
};

const ENGLISH_TRANSLATIONS = {
  title: {
    [CommunicationError.ConnectionFailure]: "Connection Failure",
    [CommunicationError.UnexpectedResponse]: "Unexpected Response",
    [CommunicationError.AbortedAndDealtWith]: "Redirecting...",
  },
  message: {
    [CommunicationError.ConnectionFailure]:
      "We're having trouble connecting. Please check your network connection and try again.",
    [CommunicationError.UnexpectedResponse]:
      "Something unexpected happened. Please try again, and if the issue persists, contact support.",
    [CommunicationError.AbortedAndDealtWith]:
      "Action was cancelled, but don't worry, we've taken care of it.",
  },
};

export function getCommunicationErrorTitle(error: CommunicationError) {
  if (error === CommunicationError.ConnectionFailure) {
    return ENGLISH_TRANSLATIONS.title[CommunicationError.ConnectionFailure];
  } else if (error === CommunicationError.UnexpectedResponse) {
    return ENGLISH_TRANSLATIONS.title[CommunicationError.UnexpectedResponse];
  } else if (error === CommunicationError.AbortedAndDealtWith) {
    return ENGLISH_TRANSLATIONS.title[CommunicationError.AbortedAndDealtWith];
  } else {
    return;
  }
}

export function getCommunicationErrorMessage(error: CommunicationError) {
  if (error === CommunicationError.ConnectionFailure) {
    return ENGLISH_TRANSLATIONS.message[CommunicationError.ConnectionFailure];
  } else if (error === CommunicationError.UnexpectedResponse) {
    return ENGLISH_TRANSLATIONS.message[CommunicationError.UnexpectedResponse];
  } else if (error === CommunicationError.AbortedAndDealtWith) {
    return ENGLISH_TRANSLATIONS.message[CommunicationError.AbortedAndDealtWith];
  } else {
    return;
  }
}

export function useCommunicationErrorTranslations() {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const getCommunicationErrorTitle = useCallback(
    (error: CommunicationError) => {
      if (error === CommunicationError.ConnectionFailure) {
        return translations.title[CommunicationError.ConnectionFailure];
      } else if (error === CommunicationError.UnexpectedResponse) {
        return translations.title[CommunicationError.UnexpectedResponse];
      } else if (error === CommunicationError.AbortedAndDealtWith) {
        return translations.title[CommunicationError.AbortedAndDealtWith];
      } else {
        return;
      }
    },
    [translations]
  );

  const getCommunicationErrorMessage = useCallback(
    (error: CommunicationError) => {
      if (error === CommunicationError.ConnectionFailure) {
        return translations.message[CommunicationError.ConnectionFailure];
      } else if (error === CommunicationError.UnexpectedResponse) {
        return translations.message[CommunicationError.UnexpectedResponse];
      } else if (error === CommunicationError.AbortedAndDealtWith) {
        return translations.message[CommunicationError.AbortedAndDealtWith];
      } else {
        return;
      }
    },
    [translations]
  );

  return { getCommunicationErrorTitle, getCommunicationErrorMessage };
}
