import { useContext, useState, useEffect } from "react";
import { z } from "zod";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BankAccount,
  BankAccountSchema,
  BankAccountsResultsContext,
} from "./results-context";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../../../../communication-errors/communication-error-messages";
import { useMainApi } from "../../../../../../../../../main-api";
import { throwError } from "../../../../../../../../../throw-error";
import styles from "./edit-account-details.module.scss";
import { useCurrentLanguage } from "../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../language/supported-languages";
import { Toast } from "primereact/toast";
import { RefObject } from "react";

const EditAccountFormSchema = z.object({
  account_name: z.string().min(1, "Account name is required"),
});

const GERMAN_TRANSLATIONS = {
  accountNameRequired: "Kontoname ist erforderlich",
  editAccountName: "Kontonamen bearbeiten",
  accountName: "Kontoname",
  save: "Speichern",
  confirmationMessage:
    "Sind Sie sicher, dass Sie diese Änderungen speichern möchten?",
  yes: "Ja",
  no: "Nein",
  confirmationHeader: "Bestätigung",
  successMessage: "Kontoname erfolgreich aktualisiert",
  rejected: "Abgelehnt",
  rejectMessage: "Änderungen wurden nicht gespeichert",
  error: "Fehler",
  success: "Erfolg",
};

const ENGLISH_TRANSLATIONS = {
  accountNameRequired: "Kontoname ist erforderlich",
  editAccountName: "Edit Account Name",
  accountName: "Account Name",
  save: "Save",
  confirmationMessage: "Are you sure you want to save these changes?",
  yes: "Yes",
  no: "No",
  confirmationHeader: "Confirmation",
  successMessage: "Account name successfully updated",
  rejected: "Rejected",
  rejectMessage: "Changes were not saved",
  error: "Error",
  success: "Success",
};

type EditAccountFormValue = z.infer<typeof EditAccountFormSchema>;

export function EditAccountDetails({
  visible,
  onHide,
  accountingClientId,
  selectedAccount,
  toastRef,
}: {
  visible: boolean;
  onHide: () => void;
  accountingClientId: string;
  selectedAccount: BankAccount;
  toastRef: RefObject<Toast>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setResults } = useContext(BankAccountsResultsContext) || throwError();

  const form = useForm<EditAccountFormValue>({
    resolver: zodResolver(EditAccountFormSchema),
    defaultValues: {
      account_name: selectedAccount.account_name,
    },
  });

  useEffect(() => {
    if (visible) {
      form.reset({ account_name: selectedAccount.account_name });
    }
  }, [selectedAccount, visible, form]);

  const handleSubmit = form.handleSubmit(() => {
    confirmDialog({
      message: translations.confirmationMessage,
      header: translations.confirmationHeader,
      icon: "pi pi-exclamation-triangle",
      acceptLabel: translations.yes,
      rejectLabel: translations.no,
      accept: async () => {
        const formData = form.getValues();
        await onSubmit(formData);
        onHide();
      },
      reject: () => {
        onHide();
        toastRef.current?.show({
          severity: "warn",
          summary: translations.rejected,
          detail: translations.rejectMessage,
        });
      },
    });
  });

  const onSubmit = async (formData: EditAccountFormValue) => {
    setIsSubmitting(true);

    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/accounting_clients/${accountingClientId}/accounts_overview/bank_accounts/${selectedAccount.id}`,
      body: {
        ...formData,
        account_name: formData.account_name,
      },
      schema: z.object({
        status: z.literal(200),
        body: BankAccountSchema,
      }),
    });

    setIsSubmitting(false);

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error) ?? translations.error,
        detail: getCommunicationErrorMessage(res.error),
      });

      return;
    }

    setResults((prev) => ({
      ...prev,
      data: prev.data
        ? {
            ...prev.data,
            rows: prev.data.rows.map((account) =>
              account.id === selectedAccount.id
                ? { ...account, account_name: formData.account_name }
                : account
            ),
          }
        : undefined,
    }));

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successMessage,
    });
  };

  return (
    <Dialog
      header={translations.editAccountName}
      visible={visible}
      onHide={onHide}
      className={styles.editAccountDialog}
      modal={true}
    >
      <form onSubmit={handleSubmit}>
        <div className={styles.formField}>
          <label htmlFor="account_name" className={styles.formFieldLabel}>
            <h4>{translations.accountName}</h4>
          </label>
          <InputText
            id="account_name"
            {...form.register("account_name")}
            className={`${styles.formFieldInput} ${
              form.formState.errors.account_name ? "p-invalid" : ""
            }`}
          />
          {form.formState.errors.account_name && (
            <small className={styles.formFieldError}>
              {translations.accountNameRequired}
            </small>
          )}
          <Button
            label={translations.save}
            type="submit"
            icon="pi pi-check"
            disabled={isSubmitting}
            loading={isSubmitting}
            className={styles.submitButton}
          />
        </div>
      </form>
    </Dialog>
  );
}
