import { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import { useMainApi } from "../../../../main-api";
import styles from "./list-finapi-accounts.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { Divider } from "primereact/divider";

import { FinApiResponseSchema, FinApiState } from "./finapi";

const GERMAN_TRANSLATIONS = {
  noBankAccountsConnected: "Keine Konten verbunden",
  refreshBankConnections: "Bankverbindungen aktualisieren",
};

const ENGLISH_TRANSLATIONS = {
  noBankAccountsConnected: "No accounts connected",
  refreshBankConnections: "Refresh Bank Connections",
};

export function ListFinApiItems({
  finApiState,
  setFinApiState,
}: {
  finApiState: FinApiState;
  setFinApiState: Dispatch<SetStateAction<FinApiState>>;
}) {
  const currentLanguage = useCurrentLanguage();
  const mainApi = useMainApi();

  useEffect(() => {
    (async () => {
      setFinApiState({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/bank-accounts/finapi",
        schema: FinApiResponseSchema,
      });

      setFinApiState({ loading: false });

      if (res.error) {
        setFinApiState({ error: res.error });
        return;
      }

      setFinApiState({ data: res.response.body });
    })();
  }, [mainApi, setFinApiState]);

  if (finApiState.loading) {
    return (
      <div className={`${styles.center}`}>
        <ProgressSpinner className={styles.loading} />
      </div>
    );
  }

  if (finApiState.error) {
    return (
      <div className={`${styles.center}`}>
        <div className={styles.errorMessage}>
          {getCommunicationErrorMessage(finApiState.error)}
        </div>
      </div>
    );
  }

  if (!finApiState.data) {
    return;
  }

  if (finApiState.data.length === 0) {
    return (
      <div className={`${styles.center} ${styles.noDataText}`}>
        <p>
          {currentLanguage === SupportedLanguage.German
            ? GERMAN_TRANSLATIONS.noBankAccountsConnected
            : ENGLISH_TRANSLATIONS.noBankAccountsConnected}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.list}>
      {finApiState.data.map((bankConnection, index) => (
        <Fragment key={bankConnection.bank_connection_id}>
          {index !== 0 && <Divider />}
          <div className={styles.item}>
            <div className={styles.col1}>
              <div className={styles.bankInfo}>
                {bankConnection.bank_logo_url && (
                  <img
                    className={styles.bankLogo}
                    src={bankConnection.bank_logo_url}
                    alt={`${bankConnection.bank_name} logo`}
                  />
                )}
                <p>{bankConnection.bank_name}</p>
              </div>
              <ul className={styles.accounts}>
                {bankConnection.accounts.map((account, idx) => (
                  <li key={idx}>
                    {account.account_name} - {account.account_iban}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
