import { ReactNode, createContext, useState } from "react";
import { DEFAULT_SORT_COLUMN } from "./sorting-constants";

export type SortColumn = {
  columnKey:
    | "account_number"
    | "account_name"
    | "total_transactions"
    | "is_connected_to_bank_acount";
  direction: "ASC" | "DESC";
};

export type SortingContextType = {
  sortColumn: SortColumn;
  setSortColumn: (column: SortColumn) => void;
};

function useContextValue() {
  const [sortColumn, setSortColumn] = useState<SortColumn>(DEFAULT_SORT_COLUMN);

  return {
    sortColumn,
    setSortColumn,
  };
}

export const NominalAccountsSortingContext = createContext<
  ReturnType<typeof useContextValue> | undefined
>(undefined);

export function NominalAccountsSortingContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useContextValue();
  return (
    <NominalAccountsSortingContext.Provider value={value}>
      {children}
    </NominalAccountsSortingContext.Provider>
  );
}
