import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { CommunicationError } from "../../communication-errors/communication-errors";
import { z } from "zod";
import { AccountantSessionSchema } from "./authentication-schemas";

type AccountantSession = z.TypeOf<typeof AccountantSessionSchema>;
type AccountantSessionState = {
  loading?: boolean;
  error?: CommunicationError;
  data?: null | AccountantSession;
};

type AccountantAuthenticationStateContextValue = {
  sessionState: AccountantSessionState;
  setSessionState: Dispatch<SetStateAction<AccountantSessionState>>;
};

export const AccountantAuthenticationStateContext = createContext<
  AccountantAuthenticationStateContextValue | undefined
>(undefined);

export function AccountantAuthenticationStateProvider(props: {
  children: React.ReactNode;
}) {
  const [sessionState, setSessionState] = useState<AccountantSessionState>({});

  const value = useMemo(() => {
    return {
      sessionState,
      setSessionState,
    };
  }, [sessionState]);

  return (
    <AccountantAuthenticationStateContext.Provider value={value}>
      {props.children}
    </AccountantAuthenticationStateContext.Provider>
  );
}
