import { ReactNode, createContext, useMemo, useState } from "react";
import { AccountingClient } from "../../../../accountingClientLoader";

export type Filters = {
  search?: string;
  is_connected_to_bank_account?: boolean | null;
};

function useContextValue(accountingClient: AccountingClient) {
  const [filters, setFilters] = useState<Filters>({
    search: "",
    is_connected_to_bank_account: null,
  });

  return useMemo(() => {
    return {
      accountingClient,
      filters,
      setFilters,
    };
  }, [accountingClient, filters, setFilters]);
}

export const NominalAccountsFiltersContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function NominalAccountsFiltersContextProvider(props: {
  accountingClient: AccountingClient;
  children: ReactNode;
}) {
  const { accountingClient, children } = props;
  const value = useContextValue(accountingClient);

  return (
    <NominalAccountsFiltersContext.Provider value={value}>
      {children}
    </NominalAccountsFiltersContext.Provider>
  );
}
