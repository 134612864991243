import { Menu as PrimeReactMenu } from "primereact/menu";
import styles from "./menu.module.scss";
import { AccountingClients } from "./accounting-clients";
import { useNavigate } from "react-router-dom";
import { AccountingClientMenu } from "./accounting-client-menu";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  dashboard: "Armaturenbrett",
};

const ENGLISH_TRANSLATIONS = {
  dashboard: "Dashboard",
};

export function Menu() {
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <PrimeReactMenu
        className={`${styles.primeReactMenu}`}
        model={[
          {
            label: translations.dashboard,
            icon: "pi pi-home",
            className: `${window.location.pathname.match(/^\/accountant$/) ? "p-menuitem-active" : ""}`,
            command: () => {
              navigate("/accountant");
            },
          },
        ]}
      />
      <hr className={styles.divider} />
      <div className={styles.section}>
        <AccountingClients />
      </div>
      <AccountingClientMenu />
    </>
  );
}
