import { useNavigate, useParams } from "react-router-dom";
import menuStyles from "./menu.module.scss";
import { Menu as PrimeReactMenu } from "primereact/menu";
import { BANK_BOOKINGS_ROUTE } from "../accounting-clients/accounting-client-id/bookings/bookings-routes";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { BANK_TRANSACTIONS_ROUTES } from "../accounting-clients/accounting-client-id/bank-transactions/bank-transactions-routes";

const GERMAN_TRANSLATIONS = {
  invoices: "Rechnungen",
  bankTransactions: "Banktransaktionen",
  bankBookings: "Bankbuchungen",
};

const ENGLISH_TRANSLATIONS = {
  invoices: "Invoices",
  bankTransactions: "Bank Transactions",
  bankBookings: "Bank Bookings",
};

export function AccountingClientMenu() {
  const params = useParams();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const currentAccountingClientId = (() => {
    const accountingClientId = params.accountingClientId;

    if (accountingClientId && typeof accountingClientId !== "string") {
      throw new Error();
    }

    return accountingClientId;
  })();

  return (
    <PrimeReactMenu
      className={menuStyles.primeReactMenu}
      model={[
        // {
        //   label: translations.invoices,
        //   className: `${window.location.pathname.match(/^\/accountant\/accounting-clients\/[^/]+\/invoices$/) ? "p-menuitem-active" : ""}`,
        //   disabled: !currentAccountingClientId,
        //   command: () => {
        //     if (currentAccountingClientId) {
        //       navigate(
        //         INVOICES_ROUTES.getHref({
        //           accountingClientId: currentAccountingClientId,
        //         })
        //       );
        //     }
        //   },
        // },
        {
          label: translations.bankTransactions,
          className: `${window.location.pathname.match(/^\/accountant\/accounting-clients\/[^/]+\/bank-transactions$/) ? "p-menuitem-active" : ""}`,
          disabled: !currentAccountingClientId,
          command: () => {
            if (currentAccountingClientId) {
              navigate(
                BANK_TRANSACTIONS_ROUTES.getHref({
                  accountingClientId: currentAccountingClientId,
                })
              );
            }
          },
        },
        {
          label: translations.bankBookings,
          className: `${window.location.pathname.match(/^\/accountant\/accounting-clients\/[^/]+\/bank-bookings$/) ? "p-menuitem-active" : ""}`,
          disabled: !currentAccountingClientId,
          command: () => {
            if (currentAccountingClientId) {
              navigate(
                BANK_BOOKINGS_ROUTE.getHref({
                  accountingClientId: currentAccountingClientId,
                })
              );
            }
          },
        },
      ]}
    />
  );
}
