import { z } from "zod";
import { QuickFiltersContext } from "../navbar/quick-filters/quick-filters-context";
import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Toast } from "primereact/toast";
import { useMainApi } from "../../../../../main-api";
import { throwError } from "../../../../../throw-error";
import { AccountingClientContext } from "../accounting-clients/accounting-client-context";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";
import { SortingContext } from "../sorting/sorting-context";
import { toDateString } from "../../../../../dates";

const ExportsSchema = z.object({
  id: z.string(),
  exported_date: z.coerce.date(),
  accounting_client: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  account: z
    .object({
      name: z.string(),
      number: z.number(),
    })
    .nullable(),
});

const JournalCountsSchema = z.object({
  historical_reduced_journals_count: z.number(),
  corrected_reduced_journals_count: z.number(),
  predicted_reduced_journals_count: z.number(),
  action_needed_reduced_journals_count: z.number(),
  total: z.number(),
});

export type JournalCount = z.TypeOf<typeof JournalCountsSchema>;
export type Export = z.TypeOf<typeof ExportsSchema>;

function useProvider({ toastRef }: { toastRef: RefObject<Toast> }) {
  const mainApi = useMainApi();
  const { sortColumn } = useContext(SortingContext) || throwError();

  const { quickFilters } = useContext(QuickFiltersContext) || throwError();

  const { selectedAccountingClient } =
    useContext(AccountingClientContext) || throwError();

  const selectedAccountingClientId = selectedAccountingClient?.id;

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        top_section: JournalCount;
        rows: Export[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [selectedExport, setSelectedExport] = useState<Export | undefined>();

  useEffect(() => {
    (async () => {
      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append("sort_by", sortColumn?.columnKey ?? "exported_at");
      urlSearchParams.append("sort_order", sortColumn?.direction ?? "DESC");

      if (selectedAccountingClientId) {
        urlSearchParams.append(
          "accounting_client_id",
          selectedAccountingClientId
        );
      }

      for (const [key, value] of Object.entries({
        ...quickFilters,
      })) {
        if (value instanceof Date) {
          urlSearchParams.append(key, toDateString(value));
        }
      }

      const [top_section, bottom_section] = await Promise.all([
        mainApi.fetchJSON({
          method: "GET",
          path: `/accounting_dashboard/metrics/top_section?${urlSearchParams.toString()}`,
          schema: z.object({
            status: z.literal(200),
            body: JournalCountsSchema,
          }),
        }),
        mainApi.fetchJSON({
          method: "GET",
          path: `/accounting_dashboard/metrics/bottom_section?${urlSearchParams.toString()}`,
          schema: z.object({
            status: z.literal(200),
            body: z.object({
              exports: z.array(ExportsSchema),
            }),
          }),
        }),
      ]);

      const error = top_section.error || bottom_section.error;

      if (error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: error,
          };
        });

        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(error),
          detail: getCommunicationErrorMessage(error),
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            top_section: top_section.response.body,
            rows: bottom_section.response.body.exports,
          },
        };
      });
    })();
  }, [mainApi, toastRef, quickFilters, selectedAccountingClientId, sortColumn]);

  return useMemo(() => {
    return {
      selectedAccountingClient,
      selectedAccountingClientId,
      results,
      selectedExport,
      setSelectedExport,
    };
  }, [
    selectedAccountingClient,
    selectedAccountingClientId,
    results,
    selectedExport,
  ]);
}

export const ResultsContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function ResultsProvider(props: { children: ReactNode }) {
  const toastRef = useRef<Toast>(null);
  const value = useProvider({ toastRef });

  return (
    <ResultsContext.Provider value={value}>
      <Toast ref={toastRef} />
      {props.children}
    </ResultsContext.Provider>
  );
}
