import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useContext, useMemo } from "react";
import { throwError } from "../../../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { BookingsPageBankAccountsContext } from "./bank-accounts-context";
import { BookingsPageResourcesContext } from "../../../page-resources";

const GERMAN_TRANSLATIONS = {
  ibanPlaceholder: "Bankkonto auswählen",
};

const ENGLISH_TRANSLATIONS = {
  ibanPlaceholder: "Select Bank Account",
};

export function BankAccountsDropdown(props: { className?: string }) {
  const { bank_accounts } =
    useContext(BookingsPageResourcesContext) || throwError();

  const { selectedBankAccount, setSelectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const bankAccountOptions = useMemo(() => {
    return bank_accounts.map((account) => ({
      label: `${account.account_number} | ${account.account_name}`,
      value: account.account_id,
    }));
  }, [bank_accounts]);

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    const selectedId = e.value;

    const selectedAccount =
      bank_accounts.find((account) => account.account_id === selectedId) ||
      throwError();

    setSelectedBankAccount(selectedAccount || null);
  };

  return (
    <Dropdown
      className={props.className}
      value={selectedBankAccount ? selectedBankAccount.account_id : null}
      options={bankAccountOptions}
      optionLabel="label"
      onChange={handleDropdownChange}
      placeholder={translations.ibanPlaceholder}
    />
  );
}
