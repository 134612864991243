import { useContext, useEffect } from "react";
import { useMainApi } from "../../main-api";
import { z } from "zod";
import { throwError } from "../../throw-error";
import { AccountantSessionSchema } from "./authentication-schemas";
import { AccountantAuthenticationStateContext } from "./authentication-state";

export function AccountantAuthEffects() {
  const mainApi = useMainApi();
  const { sessionState, setSessionState } =
    useContext(AccountantAuthenticationStateContext) || throwError();

  useEffect(() => {
    (async () => {
      if (
        sessionState.data === undefined &&
        !sessionState.loading &&
        !sessionState.error
      ) {
        setSessionState({ loading: true });

        const res = await mainApi.fetchJSON({
          path: "/accountant_users/session",
          method: "GET",
          schema: z.union([
            z.object({
              status: z.literal(404),
            }),
            z.object({
              status: z.literal(200),
              body: AccountantSessionSchema,
            }),
          ]),
        });

        if (res.error) {
          setSessionState({
            error: res.error,
          });

          return;
        }

        if (res.response.status === 404) {
          setSessionState({ data: null });

          return;
        }

        setSessionState({ data: res.response.body });
      }
    })();
  }, [sessionState, mainApi, setSessionState]);

  return <></>;
}
