import { Dropdown } from "primereact/dropdown";
import { useContext } from "react";
import { AccountingClientsContext } from "./accounting-clients-context";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "./accounting-clients.module.scss";
import { SelectItem } from "primereact/selectitem";
import { throwError } from "../../../throw-error";
import { getCommunicationErrorMessage } from "../../../communication-errors/communication-error-messages";
import { Skeleton } from "primereact/skeleton";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { BANK_TRANSACTIONS_ROUTES } from "../accounting-clients/accounting-client-id/bank-transactions/bank-transactions-routes";

const GERMAN_TRANSLATIONS = {
  selectClient: "Kunden auswählen",
};

const ENGLISH_TRANSLATIONS = {
  selectClient: "Select a client",
};

export function AccountingClients() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const params = useParams();
  const navigate = useNavigate();

  const currentAccountingClientId = (() => {
    const accountingClientId = params.accountingClientId;

    if (accountingClientId && typeof accountingClientId !== "string") {
      throw new Error();
    }

    return accountingClientId;
  })();

  const { accountingClients } =
    useContext(AccountingClientsContext) || throwError();

  const accountingClientsData = accountingClients.data;

  if (accountingClientsData) {
    const currentClient = accountingClientsData.find(
      (client) => client.id === currentAccountingClientId
    );

    return (
      <Dropdown
        className={styles.dropdown}
        value={currentClient?.id}
        onChange={(e) => {
          const clientId = e.value;

          if (!currentAccountingClientId) {
            navigate(
              BANK_TRANSACTIONS_ROUTES.getHref({ accountingClientId: clientId })
            );
          } else {
            const pathname = window.location.pathname;

            navigate(pathname.replace(currentAccountingClientId, clientId));
          }
        }}
        options={accountingClientsData.map((a) => ({
          value: a.id,
          label: a.name,
        }))}
        placeholder={translations.selectClient}
        valueTemplate={(option: SelectItem | null, props) => {
          if (!option) {
            return <div className={styles.item}>{props.placeholder}</div>;
          }

          const accountingClient =
            accountingClientsData.find((a) => a.id === option.value) ??
            throwError();

          return (
            <div className={styles.item}>
              <div className={styles.label}>{accountingClient.name}</div>
              <div className={styles.subLabel}>
                {accountingClient.client_number || ""}
              </div>
            </div>
          );
        }}
        itemTemplate={(option: SelectItem) => {
          const accountingClient =
            accountingClientsData.find((a) => a.id === option.value) ??
            throwError();

          return (
            <div className={styles.item}>
              <div className={styles.label}>{accountingClient.name}</div>
              <div className={styles.subLabel}>
                {accountingClient.client_number || ""}
              </div>
            </div>
          );
        }}
        filter
      />
    );
  }

  if (accountingClients.loading) {
    return <Skeleton height="2rem"></Skeleton>;
  }

  if (accountingClients.error) {
    return (
      <div className={styles.error}>
        {getCommunicationErrorMessage(accountingClients.error)}
      </div>
    );
  }
}
