import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../main-api";
import { useAccountingClientId } from "../../accounting-client-id";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { SortColumn, SortingContext } from "../sorting/sorting-context";
import {
  Filters,
  FiltersContext,
} from "../top-section/navbar/filters/filters-context";
import {
  QuickFilters,
  QuickFiltersContext,
} from "../top-section/navbar/quick-filters/quick-filters-context";
import { toDateString } from "../../../../../../dates";
import { throwError } from "../../../../../../throw-error";
import { BookingsPageBankAccountsContext } from "../top-section/navbar/quick-filters/bank-accounts-context";

export const BookingSchema = z.object({
  id: z.string(),
  transaction_date: z.coerce.date(),
  amount: z.coerce.number(),
  is_debit: z.boolean(),
  counter_account: z
    .object({
      id: z.string(),
      name: z.string(),
      number: z.number(),
    })
    .nullable(),
  tax_code: z
    .object({
      id: z.string(),
      tax_code: z.number(),
      description: z.string(),
    })
    .nullable(),
  description: z.string().nullable(),
  issues: z.array(
    z.union([
      z.object({
        id: z.string(),
        closed: z.boolean(),
        type: z.literal("UNRELIABLE_PREDICTION"),
        description: z.string(),
      }),
      z.object({
        id: z.string(),
        closed: z.boolean(),
        type: z.literal("MULTIPLE_TAX_CODES"),
        tax_code_ids: z.array(z.string()),
      }),
      z.object({
        id: z.string(),
        closed: z.boolean(),
        type: z.literal("COULD_NOT_PREDICT_TAX_CODE"),
      }),
    ])
  ),
  status: z.union([
    z.literal("PREDICTED_WITHOUT_ISSUES"),
    z.literal("ACTION_NEEDED"),
    z.literal("CORRECTED_BY_ACCOUNTANT"),
    z.literal("HISTORICAL"),
  ]),
  applicant_iban: z.string().nullable(),
  applicant_name: z.string().nullable(),
});

export type Booking = z.TypeOf<typeof BookingSchema>;

type RequestedParams = {
  offset: number;
  sortColumn: SortColumn | undefined;
  filters: Filters;
  quickFilters: QuickFilters;
  accountingClientId: string;
  selectedBankAccountId: string | undefined;
};

function useProvider({ toastRef }: { toastRef: RefObject<Toast> }) {
  const mainApi = useMainApi();
  const { sortColumn } = useContext(SortingContext) || throwError();

  const { filters } = useContext(FiltersContext) || throwError();
  const { quickFilters } = useContext(QuickFiltersContext) || throwError();

  const accountingClientId = useAccountingClientId();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const selectedBankAccountId = selectedBankAccount?.account_id;

  const [offset, setOffset] = useState(0);

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        total: number;
        rows: Booking[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [lastRequestParams, setLastRequestParams] = useState<
    RequestedParams | undefined
  >();

  const [selectedBookingId, setSelectedBookingId] = useState<
    string | undefined
  >();

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }

      const requestParams: RequestedParams = {
        offset,
        sortColumn,
        filters,
        quickFilters,
        accountingClientId,
        selectedBankAccountId,
      };

      if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
        return;
      }

      setLastRequestParams(requestParams);

      const resetResults =
        JSON.stringify({
          accountingClientId: requestParams.accountingClientId,
          selectedBankAccountId: requestParams.selectedBankAccountId,
          sortColumn: requestParams.sortColumn,
          filters: requestParams.filters,
          quickFilters: requestParams.quickFilters,
        }) !==
        JSON.stringify({
          accountingClientId: lastRequestParams?.accountingClientId,
          selectedBankAccountId: lastRequestParams?.selectedBankAccountId,
          sortColumn: lastRequestParams?.sortColumn,
          filters: lastRequestParams?.filters,
          quickFilters: lastRequestParams?.quickFilters,
        });

      if (resetResults) {
        setResults(() => {
          return {};
        });
      }

      const _offset = resetResults ? 0 : offset;

      /* --- */

      if (!selectedBankAccountId) {
        setResults({ data: { total: 0, rows: [] } });
        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append(
        "sort_by",
        sortColumn?.columnKey ?? "transaction_date"
      );
      urlSearchParams.append("sort_order", sortColumn?.direction ?? "DESC");
      urlSearchParams.append("offset", _offset.toString());
      urlSearchParams.append("selected_account_id", selectedBankAccountId);

      filters.status.forEach((status) => {
        urlSearchParams.append("status", status);
      });

      if (filters.amount_min != null) {
        urlSearchParams.append("amount_min", filters.amount_min.toString());
      }

      if (filters.amount_max != null) {
        urlSearchParams.append("amount_max", filters.amount_max.toString());
      }

      if (filters.is_debit != null) {
        urlSearchParams.append("is_debit", filters.is_debit.toString());
      }

      if (filters.counter_account != null) {
        urlSearchParams.append("counter_account", filters.counter_account);
      }

      if (quickFilters.time_frame_to != null) {
        urlSearchParams.append(
          "time_frame_to",
          toDateString(quickFilters.time_frame_to)
        );
      }

      if (quickFilters.time_frame_from != null) {
        urlSearchParams.append(
          "time_frame_from",
          toDateString(quickFilters.time_frame_from)
        );
      }

      const response = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_dashboard/${accountingClientId}/bookings?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            total: z.number(),
            bookings: z.array(BookingSchema),
          }),
        }),
      });

      if (response.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: response.error,
          };
        });

        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(response.error),
          detail: getCommunicationErrorMessage(response.error),
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            total: response.response.body.total,
            rows: resetResults
              ? response.response.body.bookings
              : [
                  ...(results.data?.rows || []),
                  ...response.response.body.bookings,
                ],
          },
        };
      });
    })();
  }, [
    accountingClientId,
    mainApi,
    offset,
    toastRef,
    results,
    lastRequestParams,
    filters,
    quickFilters,
    sortColumn,
    selectedBankAccountId,
  ]);

  const selectedBooking = results.data?.rows.find(
    (booking) => booking.id === selectedBookingId
  );

  return useMemo(() => {
    return {
      offset,
      setOffset,
      selectedBookingId,
      setSelectedBookingId,
      results,
      setResults,
      selectedBankAccountId,
      selectedBooking,
    };
  }, [
    offset,
    selectedBookingId,
    results,
    selectedBooking,
    selectedBankAccountId,
  ]);
}

export const ResultsContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function ResultsContextProvider(props: { children: ReactNode }) {
  const toastRef = useRef<Toast>(null);
  const value = useProvider({ toastRef });

  return (
    <ResultsContext.Provider value={value}>
      <Toast ref={toastRef} />
      {props.children}
    </ResultsContext.Provider>
  );
}
