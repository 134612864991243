import { useState } from "react";
import styles from "./documents.module.scss";
import UploadGDPDUButton from "./gdpdu/upload-gdpdu-button";
import GdpduUploads from "./gdpdu-uploads/gdpdu-uploads";
import { AccountingClient } from "../accountingClientLoader";

function Documents(props: {
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
}) {
  const [forcedUpdateKey, setForcedUpdateKey] = useState(0);

  return (
    <div className={styles.page}>
      <UploadGDPDUButton
        accountingClient={props.accountingClient}
        setAccountingClient={props.setAccountingClient}
        setForcedUpdateKey={setForcedUpdateKey}
      />
      <GdpduUploads forcedUpdateKey={forcedUpdateKey} />
    </div>
  );
}

export default Documents;
