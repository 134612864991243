import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useContext, useMemo } from "react";
import { BankTransactionsPageBankAccountsContext } from "./bank-accounts-context";
import { throwError } from "../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  ibanPlaceholder: "IBAN",
};

const ENGLISH_TRANSLATIONS = {
  ibanPlaceholder: "IBAN",
};

export function BankAccountsDropdown(props: { className?: string }) {
  const { bankAccounts, selectedBankAccount, setSelectedBankAccount } =
    useContext(BankTransactionsPageBankAccountsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const bankAccountOptions = useMemo(() => {
    return bankAccounts.map((account) => ({
      label: `${account.iban}`,
      value: account.id,
    }));
  }, [bankAccounts]);

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    const selectedId = e.value;

    const selectedAccount =
      bankAccounts.find((account) => account.id === selectedId) || throwError();

    setSelectedBankAccount(selectedAccount || null);
  };

  return (
    <Dropdown
      className={props.className}
      value={selectedBankAccount ? selectedBankAccount.id : null}
      options={bankAccountOptions}
      optionLabel="label"
      onChange={handleDropdownChange}
      placeholder={translations.ibanPlaceholder}
    />
  );
}
