import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import styles from "./policies.module.scss";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  title: "Allgemeine Geschäftsbedingungen",
  sections: [
    {
      title: "1. Einleitung und Definitionen",
      content: `Diese Allgemeinen Geschäftsbedingungen ("Bedingungen") regeln die Nutzung der Buchhaltungsdienstleistungen ("Dienstleistungen"), die von Tech Jobs International OÜ ("Unternehmen", "wir", "uns" oder "unser") über unsere Web- und mobile Anwendung bereitgestellt werden. Durch die Nutzung unserer Dienstleistungen stimmen Sie ("Nutzer" oder "Sie") diesen Bedingungen zu. Zu den Schlüsselbegriffen in diesem Dokument gehören:`,
      subsections: [
        {
          title: "- Nutzer",
          content: `Jede Einzelperson oder juristische Person, die ein Konto erstellt oder die Dienstleistungen nutzt.`,
        },
        {
          title: "- Dienstleistungen",
          content: `Die über unsere Plattform angebotenen Buchhaltungs-, Finanzverwaltungs- und verwandten Dienstleistungen.`,
        },
      ],
    },
    {
      title: "2. Nutzerberechtigung",
      content: `Unsere Dienstleistungen stehen Personen zur Verfügung, die mindestens 18 Jahre alt sind und rechtlich in der Lage sind, Verträge abzuschließen. Die Dienstleistungen sind für Unternehmen, Buchhalter, Finanzfachleute und private Personen in der Europäischen Union, insbesondere Deutschland, bestimmt. Nutzer müssen alle geltenden lokalen und internationalen Gesetze einhalten.`,
    },
    {
      title: "3. Dienstleistungsbeschreibung",
      content: `Das Unternehmen bietet eine Online-Plattform, die Buchhaltungs- und Finanzmanagement-Tools bereitstellt. Dazu gehören unter anderem Buchführung, Finanzberichterstattung und Steuerberechnungsfunktionen. Unsere Dienstleistungen sind darauf ausgelegt, Nutzern bei der Verwaltung ihrer Finanzen zu helfen; jedoch geben wir keine individuellen Finanz- oder Steuerberatung. Die Dienstleistungen werden "wie sie sind" angeboten und können Einschränkungen wie Serverausfälle oder Änderungen in der Verfügbarkeit unterliegen.`,
    },
    {
      title: "4. Nutzerverantwortlichkeiten",
      content: `Als Nutzer stimmen Sie zu, bei der Registrierung für unsere Dienstleistungen und deren Nutzung genaue, vollständige und aktuelle Informationen bereitzustellen. Sie sind dafür verantwortlich, dass Ihre Nutzung der Dienstleistungen allen geltenden Buchhaltungs- und Steuerrecht entspricht. Nutzer sind allein verantwortlich für die Rechtmäßigkeit, Zuverlässigkeit und Genauigkeit ihrer in die Plattform eingegebenen Finanzdaten.`,
    },
    {
      title: "5. Datenschutz und Einhaltung der DSGVO",
      content: `Wir verpflichten uns, Ihre Privatsphäre zu schützen und Ihre Daten in Übereinstimmung mit der Datenschutz-Grundverordnung (DSGVO) zu behandeln. Durch die Nutzung unserer Dienstleistungen stimmen Sie unserer Erhebung, Speicherung und Verwendung personenbezogener Daten zu, wie in unserer Datenschutzerklärung beschrieben. Wir setzen geeignete technische und organisatorische Maßnahmen um, um Ihre personenbezogenen Daten vor unbefugtem Zugriff, Verwendung oder Offenlegung zu schützen.`,
    },
    {
      title: "6. Geistiges Eigentum",
      content: `Alle geistigen Eigentumsrechte an der Software, den Inhalten und Materialien, die über die Dienstleistungen bereitgestellt werden, bleiben im Eigentum des Unternehmens oder unserer Lizenzgeber. Den Nutzern wird eine eingeschränkte, nicht exklusive, nicht übertragbare Lizenz zur Nutzung der Dienstleistungen gewährt. Die Nutzer erwerben kein Eigentum an Software oder Inhalten, die über die Plattform abgerufen werden.`,
    },
    {
      title: "7. Nutzerkonto und Sicherheit",
      content: `Um unsere Dienstleistungen nutzen zu können, müssen die Nutzer ein Konto erstellen, indem sie eine gültige E-Mail-Adresse angeben und ein Passwort wählen. Die Nutzer sind dafür verantwortlich, die Vertraulichkeit ihrer Kontodaten zu wahren und für alle Aktivitäten, die unter ihrem Konto stattfinden. Das Unternehmen implementiert branchenübliche Sicherheitsmaßnahmen, aber die Nutzer sind ebenfalls dafür verantwortlich, ihre Anmeldeinformationen zu sichern.`,
    },
    {
      title: "8. Kündigung des Dienstes",
      content: `Beide Parteien können diese Vereinbarung jederzeit kündigen. Das Unternehmen behält sich das Recht vor, Ihren Zugang zu den Dienstleistungen zu sperren oder zu kündigen, wenn Sie gegen diese Bedingungen verstoßen oder wenn die Dienstleistungen eingestellt werden. Nach der Kündigung haben Sie keinen Zugang mehr zu Ihrem Konto, und alle Daten können gelöscht werden, es sei denn, dies ist gesetzlich erforderlich.`,
    },
    {
      title: "9. Haftungsbeschränkungen",
      content: `Soweit gesetzlich zulässig, ist das Unternehmen nicht haftbar für direkte, indirekte, zufällige oder Folgeschäden, die sich aus Ihrer Nutzung der Dienstleistungen ergeben, einschließlich, aber nicht beschränkt auf finanzielle Verluste oder Datenverletzungen. Die Nutzer übernehmen die volle Verantwortung für die Genauigkeit und Rechtmäßigkeit der Finanzdaten, die sie in die Plattform eingeben.`,
    },
    {
      title: "10. Streitbeilegung",
      content: `Im Falle eines Streits, der sich aus diesen Bedingungen oder Ihrer Nutzung der Dienstleistungen ergibt, vereinbaren beide Parteien, die Angelegenheit durch verbindliche Schiedsverfahren nach deutschem Recht zu klären. Die ausschließliche Zuständigkeit für rechtliche Schritte im Zusammenhang mit diesen Bedingungen liegt bei den Gerichten in Berlin, Deutschland.`,
    },
    {
      title: "11. Änderungen der Bedingungen",
      content: `Wir behalten uns das Recht vor, diese Bedingungen jederzeit zu aktualisieren oder zu ändern. Änderungen werden den Nutzern per E-Mail oder über die Plattform mitgeteilt. Die fortgesetzte Nutzung der Dienstleistungen nach den Änderungen stellt Ihre Zustimmung zu den neuen Bedingungen dar.`,
    },
    {
      title: "12. Dienstleistungen Dritter",
      content: `Unsere Plattform kann Integrationen mit Drittanbieterdiensten enthalten. Das Unternehmen ist nicht verantwortlich für die Verfügbarkeit, Qualität oder rechtliche Konformität dieser Drittanbieterdienste, und die Nutzer erklären sich bereit, diese auf eigenes Risiko zu nutzen. Unsere Haftung für Drittanbieterdienste ist gemäß den anwendbaren Vereinbarungen mit diesen Anbietern beschränkt.`,
    },
    {
      title: "13. Einhaltung deutscher Gesetze",
      content: `Durch die Nutzung unserer Dienstleistungen erklären Sie sich damit einverstanden, alle geltenden deutschen und EU-Buchhaltungs- und Finanzgesetze einzuhalten. Dies umfasst unter anderem die Einhaltung von Steuervorschriften, Berichtspflichten und allen anderen relevanten gesetzlichen Anforderungen.`,
    },
    {
      title: "14. Kontaktinformationen",
      content: `Wenn Sie Fragen zu diesen Bedingungen haben oder Unterstützung benötigen, kontaktieren Sie unser Support-Team unter:`,
      subsections: [
        {
          title: "- Email:",
          content: "[support@kanzlei21.com](mailto: support@kanzlei21.com)",
        },
        {
          title: "- Adresse:",
          content: `Järvevana tee 9,
                            Kesklinna,
                            11314, Tallinn,
                            Harju, Estonia`,
        },
        {
          title: "- Telefon:",
          content: "+49 30 405 18764",
        },
        {
          title: "- USt - IdNr.:",
          content: "EE102721287",
        },
        {
          title: "- Registercode:",
          content: "16916439",
        },
        {
          title: "- Telefonnummer:",
          content: "+49 30 405 18764",
        },
      ],
    },
  ],
};

const ENGLISH_TRANSLATIONS = {
  title: "Terms and Conditions",
  sections: [
    {
      title: "1. Introduction and Definitions",
      content: `These Terms and Conditions("Terms") govern the use of the accounting services("Services") provided by Tech Jobs International OÜ("Company", "we", "us", or "our") through our web and mobile application.By using our Services, you ("User" or "you") agree to these Terms.Key terms used throughout this document include: `,
      subsections: [
        {
          title: "- User:",
          content: `Any individual or business entity that creates an account or uses the Services.`,
        },
        {
          title: "- Services:",
          content: `The accounting, financial management, and related services offered through our platform.`,
        },
      ],
    },
    {
      title: "2. User Eligibility",
      content: `Our Services are available to individuals who are at least 18 years old and legally capable of entering into contracts.The Services are intended for use by businesses, accountants, financial professionals, and private individuals located within the European Union, particularly Germany.Users must comply with all applicable local and international laws.`,
    },
    {
      title: "3. Service Description",
      content: `The Company provides an online platform offering accounting and financial management tools.These include, but are not limited to, bookkeeping, financial reporting, and tax calculation features.Our Services are designed to assist Users in managing their finances; however, we do not provide personalized financial or tax advice.The Services are offered "as is" and may be subject to limitations such as server downtime or changes in availability.`,
    },
    {
      title: "4. User Responsibilities",
      content: `As a User, you agree to provide accurate, complete, and up - to - date information when registering for our Services and using them.You are responsible for ensuring that your use of the Services complies with all applicable accounting and tax laws.Users are solely responsible for the legality, reliability, and accuracy of their financial data entered into the platform.`,
    },
    {
      title: "5. Data Privacy and GDPR Compliance",
      content: `We are committed to safeguarding your privacy and handling your data in compliance with the General Data Protection Regulation(GDPR).By using our Services, you consent to our collection, storage, and use of personal data as described in our Privacy Policy.We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, or disclosure.`,
    },
    {
      title: "6. Intellectual Property",
      content: `All intellectual property rights in the software, content, and materials provided through the Services remain the property of the Company or our licensors.Users are granted a limited, non - exclusive, non - transferable license to use the Services.Users do not acquire ownership of any software or content accessed through the platform.`,
    },
    {
      title: "7. User Account and Security",
      content: `To access our Services, Users must create an account by providing a valid email address and selecting a password.Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.The Company implements industry - standard security measures, but Users are also responsible for securing their login credentials.`,
    },
    {
      title: "8. Termination of Service",
      content: `Either party may terminate this Agreement at any time.The Company reserves the right to suspend or terminate your access to the Services if you violate these Terms or if the Services are discontinued.Upon termination, you will no longer have access to your account, and all data may be deleted unless required by law.`,
    },
    {
      title: "9. Limitation of Liability",
      content: `To the fullest extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the Services, including but not limited to financial losses or data breaches.Users assume full responsibility for the accuracy and legality of the financial data they enter into the platform.`,
    },
    {
      title: "10. Dispute Resolution",
      content: `In the event of a dispute arising from these Terms or your use of the Services, both parties agree to resolve the matter through binding arbitration in accordance with German law.The exclusive jurisdiction for legal actions relating to these Terms shall be with the courts in Berlin, Germany.`,
    },
    {
      title: "11. Changes to the Terms",
      content: `We reserve the right to update or modify these Terms at any time.Changes will be communicated to Users via email or through the platform.Continued use of the Services after changes constitutes your acceptance of the new Terms.`,
    },
    {
      title: "12. Third-Party Services",
      content: `Our platform may include integrations with third - party services.The Company is not responsible for the availability, quality, or legal compliance of these third - party services, and Users agree to use them at their own risk.Our liability for third - party services is limited according to the applicable agreements with these providers.`,
    },
    {
      title: "13. Compliance with German Law",
      content: `By using our Services, you agree to comply with all applicable German and EU accounting and financial laws.This includes, but is not limited to, compliance with tax regulations, reporting obligations, and any other relevant legal requirements.`,
    },
    {
      title: "14. Contact Information",
      content: `If you have any questions regarding these Terms or need assistance, please contact our support team at: `,
      subsections: [
        {
          title: "- Email:",
          content: "[support@kanzlei21.com](mailto: support@kanzlei21.com)",
        },
        {
          title: "- Address:",
          content: `Järvevana tee 9,
                                Kesklinna,
                                11314, Tallinn,
                                Harju, Estonia`,
        },
        {
          title: "- Phone:",
          content: "+49 30 405 18764",
        },
        {
          title: "- VAT:",
          content: "EE102721287",
        },
        {
          title: "- Registry Code:",
          content: "16916439",
        },
        {
          title: "- Phone Number:",
          content: "+49 30 405 18764",
        },
      ],
    },
  ],
};

const TermsAndConditions = () => {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <Card className={styles.policyCard}>
      <h1>{translations.title}</h1>
      {translations.sections.map((section, index) => (
        <div key={index}>
          <h3>{section.title}</h3>
          <p className={styles.content}>{section.content}</p>
          {section.subsections &&
            section.subsections.map((subsection, subIndex) => (
              <div key={subIndex} className={styles.subsection}>
                <h4>{subsection.title}</h4>
                <p>{subsection.content}</p>
              </div>
            ))}
          <Divider />
        </div>
      ))}
    </Card>
  );
};

export default TermsAndConditions;
