import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useState, useRef } from "react";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../../main-api";
import { throwError } from "../../../../../../../throw-error";
import { useAccountingClientId } from "../../../accounting-client-id";
import { AccountingClient } from "../../accountingClientLoader";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  uploadSuccess: "GDPDU-Datei erfolgreich hochgeladen und verarbeitet",
  uploadError: "Fehler beim Hochladen der GDPDU-Datei",
  uploadGDPDUFile: "GDPDU-Datei hochladen",
  success: "Erfolg",
};

const ENGLISH_TRANSLATIONS = {
  uploadSuccess: "GDPDU file uploaded and processed successfully",
  uploadError: "Error uploading GDPDU file",
  unexpectedResponse: "Unexpected server response",
  uploadGDPDUFile: "Upload GDPDU-File",
  success: "Success",
};

function UploadGDPDUButton(props: {
  className?: string;
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
  setForcedUpdateKey: React.Dispatch<React.SetStateAction<number>>;
}) {
  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();

  const [isUploading, setIsUploading] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const toastRef = useRef<Toast | null>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Toast ref={toastRef} />
      <input
        type="file"
        onChange={(e) => {
          (async () => {
            const file = e.target.files?.[0] ?? throwError();

            setIsUploading(true);

            const urlSearchParams = new URLSearchParams();

            urlSearchParams.append("accounting_client_id", accountingClientId);

            const formData = new FormData();
            formData.append("file", file);

            const res = await mainApi.fetch(
              `/gdpdu_upload?${urlSearchParams.toString()}`,
              [200],
              {
                method: "POST",
                body: formData,
              }
            );

            inputRef.current!.value = "";

            setIsUploading(false);

            if (res.error) {
              toastRef.current!.show({
                severity: "error",
                summary: getCommunicationErrorTitle(res.error),
                detail: getCommunicationErrorMessage(res.error),
              });

              return;
            }

            if (!res.response.ok) {
              toastRef.current!.show({
                severity: "error",
                summary: getCommunicationErrorTitle(
                  CommunicationError.UnexpectedResponse
                ),
                detail: getCommunicationErrorMessage(
                  CommunicationError.UnexpectedResponse
                ),
              });

              return;
            }

            toastRef.current!.show({
              severity: "success",
              summary: translations.success,
              detail: translations.uploadSuccess,
            });

            props.setAccountingClient({
              ...props.accountingClient,
              has_gdpdu_upload: true,
            });

            props.setForcedUpdateKey?.((prevKey: number) => prevKey + 1);
          })();
        }}
        style={{ display: "none" }}
        ref={inputRef}
      />
      <Button
        icon={"pi pi-file-import"}
        className={props.className}
        disabled={isUploading}
        loading={isUploading}
        label={translations.uploadGDPDUFile}
        onClick={() => {
          inputRef.current!.click();
        }}
      />
    </>
  );
}

export default UploadGDPDUButton;
