import { z } from "zod";
import { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Actions } from "./actions/actions";
import { Details } from "./details/details";
import { Issues } from "./issues/issues";
import styles from "./details.module.scss";
import { Panel } from "primereact/panel";
import {
  ResultsContext,
  Booking,
  BookingSchema,
} from "../results/results-context";
import { throwError } from "../../../../../../throw-error";
import { Divider } from "primereact/divider";
import { useMainApi } from "../../../../../../main-api";
import { useAccountingClientId } from "../../accounting-client-id";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { BookingsPageBankAccountsContext } from "../top-section/navbar/quick-filters/bank-accounts-context";

const GERMAN_TRANSLATIONS = {
  success: "Erfolg",
  successMessage: "Buchungdetails erfolgreich aktualisiert",
  error: "Fehler",
};

const ENGLISH_TRANSLATIONS = {
  success: "Success",
  successMessage: "Booking details successfully updated",
  error: "Error",
};

export const EditBookingDetailsFormSchema = z.object({
  counter_account_id: z.string().nullable(),
  tax_code_id: z.string().nullable(),
});

export type EditBookingDetailsFormValue = z.infer<
  typeof EditBookingDetailsFormSchema
>;

function _DetailsPanel({ selectedBooking }: { selectedBooking: Booking }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { setResults } = useContext(ResultsContext) || throwError();

  const mainApi = useMainApi();

  const accountingClientId = useAccountingClientId();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const toastRef = useRef<Toast>(null);

  const form = useForm<EditBookingDetailsFormValue>({
    resolver: zodResolver(EditBookingDetailsFormSchema),
    defaultValues: {
      counter_account_id: selectedBooking.counter_account?.id ?? null,
      tax_code_id: selectedBooking.tax_code?.id ?? null,
    },
  });

  useEffect(() => {
    form.reset({
      counter_account_id: selectedBooking.counter_account?.id ?? null,
      tax_code_id: selectedBooking.tax_code?.id ?? null,
    });
  }, [form, selectedBooking]);

  const onSubmit = async (formData: EditBookingDetailsFormValue) => {
    const urlSearchParams = new URLSearchParams();

    if (!selectedBankAccount) {
      throw new Error();
    }

    urlSearchParams.append(
      "selected_account_id",
      selectedBankAccount.account_id
    );

    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/accounting_dashboard/${accountingClientId}/bookings/${selectedBooking?.id}/edit_details?${urlSearchParams.toString()}`,
      body: {
        ...formData,
        counter_account_id: formData.counter_account_id,
        tax_code_id: formData.tax_code_id,
      },
      schema: z.object({
        status: z.literal(200),
        body: BookingSchema,
      }),
    });

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error) ?? translations.error,
        detail: getCommunicationErrorMessage(res.error),
      });

      return;
    }

    setResults((results) => ({
      ...results,
      data: results.data && {
        ...results.data,
        rows: results.data.rows.map((b) =>
          b.id === selectedBooking?.id ? { ...b, ...res.response.body } : b
        ),
      },
    }));

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successMessage,
    });
  };

  return (
    <>
      <Toast ref={toastRef} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Panel
          headerTemplate={() => (
            <Actions
              selectedBooking={selectedBooking}
              isSubmitting={form.formState.isSubmitting}
            />
          )}
        >
          <div className={styles.row}>
            <div className={styles.details}>
              <Details selectedBooking={selectedBooking} form={form} />
            </div>
            <Divider layout="vertical" />
            <div className={styles.issues}>
              <Issues selectedBooking={selectedBooking} />
            </div>
          </div>
        </Panel>
      </form>
    </>
  );
}

export function DetailsPanel() {
  const { selectedBooking } = useContext(ResultsContext) || throwError();

  if (!selectedBooking) {
    return;
  }

  return <_DetailsPanel selectedBooking={selectedBooking} />;
}
