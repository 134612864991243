import * as Sentry from "@sentry/react";

const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const VITE_SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;
const VITE_DISABLE_SESSION_REPLAYS = import.meta.env
  .VITE_DISABLE_SESSION_REPLAYS;

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    environment: (() => {
      if (VITE_SENTRY_ENVIRONMENT === "production") {
        return "production";
      } else if (VITE_SENTRY_ENVIRONMENT === "staging") {
        return "staging";
      } else {
        throw new Error();
      }
    })(),
    normalizeDepth: 50,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    ...(() => {
      if (VITE_DISABLE_SESSION_REPLAYS === "true") {
        return {
          replaysSessionSampleRate: 0,
          replaysOnErrorSampleRate: 0,
        };
      } else {
        return {
          replaysSessionSampleRate: 0,
          replaysOnErrorSampleRate: 1.0,
        };
      }
    })(),
  });
}
