import { JournalCount } from "../result-context";
import { Counts } from "./counts/counts";
import { Graph } from "./graph/graph";

export function Overview({
  topSectionData: topSection,
}: {
  topSectionData: JournalCount;
}) {
  return (
    <>
      <Counts journalCounts={topSection} />
      <Graph journalCounts={topSection} />
    </>
  );
}
