import { SupportedLanguage } from "./supported-languages";

export function useCurrentLanguage() {
  if (
    window.navigator.language === "de" ||
    window.navigator.language === "de-DE"
  ) {
    return SupportedLanguage.German;
  } else {
    return SupportedLanguage.English;
  }
}
