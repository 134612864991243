import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { MutableRefObject, ReactNode, useEffect, useState } from "react";
import styles from "./accounting-firm-loader.module.scss";
import { useMainApi } from "../../../main-api";
import { z } from "zod";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../communication-errors/communication-error-messages";

const AccountingFirmSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type AccountingFirm = z.infer<typeof AccountingFirmSchema>;

export function AccountingFirmLoader(props: {
  toast: MutableRefObject<Toast | null>;
  children: (renderProps: { accountingFirm: AccountingFirm }) => ReactNode;
}) {
  const mainApi = useMainApi();

  const [state, setState] = useState<{
    loading?: boolean;
    data?: AccountingFirm;
  }>({});

  useEffect(() => {
    (async () => {
      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/accounting_firms/current",
        schema: z.object({
          status: z.literal(200),
          body: AccountingFirmSchema,
        }),
      });

      if (res.error) {
        props.toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        setState({});
        return;
      }

      setState({ loading: false, data: res.response.body });
    })();
  }, [mainApi, props.toast]);

  if (state.loading) {
    return (
      <div className={styles.loading}>
        <ProgressSpinner />
      </div>
    );
  }

  if (!state.data) {
    return null;
  }

  return <>{props.children({ accountingFirm: state.data })}</>;
}
