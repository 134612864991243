import { ReactNode, createContext, useMemo, useState } from "react";
import { useAccountingClientId } from "../../accounting-client-id";
import { BankAccount } from "./bank-accounts-loader";

function useContextValue(props: { bank_accounts: BankAccount[] }) {
  const accountingClientId = useAccountingClientId();

  const [selectedBankAccount, setSelectedBankAccount] = useState<
    BankAccount | undefined
  >(props.bank_accounts[0]);

  return useMemo(() => {
    return {
      accountingClientId,
      selectedBankAccount,
      setSelectedBankAccount,
      bankAccounts: props.bank_accounts,
    };
  }, [
    accountingClientId,
    props.bank_accounts,
    selectedBankAccount,
    setSelectedBankAccount,
  ]);
}

export const BankTransactionsPageBankAccountsContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function BankTransactionsBankAccountsProvider(props: {
  bank_accounts: BankAccount[];
  children: ReactNode;
}) {
  const value = useContextValue({ bank_accounts: props.bank_accounts });

  return (
    <BankTransactionsPageBankAccountsContext.Provider value={value}>
      {props.children}
    </BankTransactionsPageBankAccountsContext.Provider>
  );
}
