import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../../main-api";
import {
  BankTransactionsPageFiltersContext,
  Filters,
} from "../filters/filters-context";
import { toDateString } from "../../../../../../dates";
import { throwError } from "../../../../../../throw-error";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { BankTransactionsPageBankAccountsContext } from "../bank-accounts/bank-accounts-context";
import {
  BankTransactionsPageSortingContext,
  SortColumn,
} from "../sorting/sorting-context";

export const BankTransactionSchema = z.object({
  id: z.string(),
  merchant_name: z.string().nullable(),
  amount: z.coerce.number().nullable(),
  created_at: z.coerce.date().nullable(),
});

export type BankTransaction = z.TypeOf<typeof BankTransactionSchema>;

type RequestedParams = {
  offset: number;
  filters: Filters;
  sortColumn: SortColumn | undefined;
  selectedBankAccountId: string | undefined;
  forcedUpdateKey: number;
};

function useContextValue() {
  const mainApi = useMainApi();

  const { sortColumn } =
    useContext(BankTransactionsPageSortingContext) || throwError();

  const { filters } =
    useContext(BankTransactionsPageFiltersContext) || throwError();

  const { selectedBankAccount } =
    useContext(BankTransactionsPageBankAccountsContext) || throwError();

  const selectedBankAccountId = selectedBankAccount?.id;

  const [offset, setOffset] = useState(0);

  const [forcedUpdateKey, setForcedUpdateKey] = useState(0);

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        total: number;
        rows: BankTransaction[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [lastRequestParams, setLastRequestParams] = useState<
    RequestedParams | undefined
  >();

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }

      const requestParams: RequestedParams = {
        offset,
        filters,
        sortColumn,
        selectedBankAccountId,
        forcedUpdateKey,
      };

      if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
        return;
      }

      setLastRequestParams(requestParams);

      const resetResults =
        JSON.stringify({
          selectedBankAccountId: requestParams.selectedBankAccountId,
          sortColumn: requestParams.sortColumn,
          filters: requestParams.filters,
        }) !==
        JSON.stringify({
          selectedBankAccountId: lastRequestParams?.selectedBankAccountId,
          sortColumn: lastRequestParams?.sortColumn,
          filters: lastRequestParams?.filters,
        });

      if (resetResults) {
        setResults(() => {
          return {};
        });
      }

      const _offset = resetResults ? 0 : offset;

      if (!selectedBankAccountId) {
        setResults({ data: { total: 0, rows: [] } });
        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append("bank_account_id", selectedBankAccountId);
      urlSearchParams.append("sort_by", sortColumn?.columnKey ?? "created_at");
      urlSearchParams.append("sort_order", sortColumn?.direction ?? "DESC");
      urlSearchParams.append("offset", _offset.toString());

      for (const [key, value] of Object.entries(filters)) {
        if (key === "entry_type") {
          if (value === "all") {
            continue;
          }

          if (typeof value !== "string") {
            throw new Error();
          }

          urlSearchParams.append(key, value);
        }
        if (typeof value === "string" && value.trim().length > 0) {
          urlSearchParams.append(key, value);
        } else if (typeof value === "number") {
          urlSearchParams.append(key, value.toString());
        } else if (value instanceof Date) {
          urlSearchParams.append(key, toDateString(value));
        }
      }

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/bank_like_transactions?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            total: z.number(),
            transactions: z.array(BankTransactionSchema),
          }),
        }),
      });

      if (res.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: res.error,
          };
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            total: res.response.body.total,
            rows: resetResults
              ? res.response.body.transactions
              : [
                  ...(results.data?.rows || []),
                  ...res.response.body.transactions,
                ],
          },
        };
      });
    })();
  }, [
    filters,
    lastRequestParams,
    mainApi,
    offset,
    results,
    selectedBankAccount,
    selectedBankAccountId,
    sortColumn,
    forcedUpdateKey,
  ]);

  return useMemo(() => {
    return {
      offset,
      setOffset,
      results,
      setResults,
      selectedBankAccount,
      selectedBankAccountId,
      setForcedUpdateKey,
    };
  }, [offset, results, selectedBankAccountId, selectedBankAccount]);
}

export const BankTransactionsPageResultsContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function BankTransactionsPageResultsProvider(props: {
  children: ReactNode;
}) {
  const value = useContextValue();

  return (
    <BankTransactionsPageResultsContext.Provider value={value}>
      {props.children}
    </BankTransactionsPageResultsContext.Provider>
  );
}
