import { ReactNode, createContext, useMemo, useState } from "react";
import { AccountingClient } from "../../../../accountingClientLoader";

export type Filters = {
  account_name?: string;
  account_number?: string;
  search?: string;
  debitor_or_creditor?: "debitor" | "creditor" | undefined;
};

function useContextValue(accountingClient: AccountingClient) {
  const [filters, setFilters] = useState<Filters>({
    account_name: "",
    account_number: "",
    search: "",
    debitor_or_creditor: undefined,
  });

  return useMemo(() => {
    return {
      accountingClient,
      filters,
      setFilters,
    };
  }, [accountingClient, filters, setFilters]);
}

export const PersonalAccountsFiltersContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function PersonalAccountsFiltersContextProvider(props: {
  accountingClient: AccountingClient;
  children: ReactNode;
}) {
  const { accountingClient, children } = props;
  const value = useContextValue(accountingClient);

  return (
    <PersonalAccountsFiltersContext.Provider value={value}>
      {children}
    </PersonalAccountsFiltersContext.Provider>
  );
}
