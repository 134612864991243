import React, { useContext, useState, useEffect, useCallback } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { throwError } from "../../../../../../../../../throw-error";
import { BankAccountsResultsContext } from "../results/results-context";
import styles from "./bank-accounts-toolbar.module.scss";
import { BankAccountsFiltersContext } from "./filters-context";
import { useCurrentLanguage } from "../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  search: "Suche",
};

const ENGLISH_TRANSLATIONS = {
  search: "Search",
};

export function BankAccountsToolbar() {
  const { filters, setFilters } =
    useContext(BankAccountsFiltersContext) || throwError();
  const results = useContext(BankAccountsResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [searchInput, setSearchInput] = useState(filters.search || "");
  const [searchTriggered, setSearchTriggered] = useState(false);

  const handleSearch = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchInput,
    }));
    setSearchTriggered(true);
  }, [searchInput, setFilters]);

  useEffect(() => {
    if (searchTriggered) {
      setSearchTriggered(false);
    }
  }, [searchTriggered, filters, results]);

  const endContent = (
    <React.Fragment>
      <InputText
        placeholder={translations.search}
        className={styles.quarterSpacerMarginRight}
        value={searchInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchInput(e.target.value)
        }
        onKeyPress={(e) => e.key === "Enter" && handleSearch()}
      />
      <Button icon="pi pi-search" onClick={handleSearch} />
    </React.Fragment>
  );

  return <Toolbar end={endContent} />;
}
