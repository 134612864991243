import { useRef, useState } from "react";
import { Accountant, AccountantsLoader } from "./accountants-loader";
import { AccountingFirm, AccountingFirmLoader } from "./accounting-firm-loader";
import { DetailsPanel } from "./details-panel";
import styles from "./page.module.scss";
import { Toast } from "primereact/toast";
import { AccountantsTable } from "./accountants-table";
import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";

const ENGLISH_TRANSLATIONS = {
  accountants: "Accountants",
  newAccountant: "New accountant",
};

const GERMAN_TRANSLATIONS = {
  accountants: "Buchhalter",
  newAccountant: "Neue Buchhalter",
};

function _AccountingFirmPage(props: {
  accountingFirm: AccountingFirm;
  accountants: Accountant[];
  onAccountantCreated: (accountant: Accountant) => void;
  onAccountantUpdated: (accountant: Accountant) => void;
}) {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [selectedAccountantId, setSelectedAccountantId] = useState<
    string | undefined
  >(undefined);

  const [isCreatingNewAccountant, setIsCreatingNewAccountant] = useState(false);

  const selectedAccountant = props.accountants.find(
    (accountant) => accountant.id === selectedAccountantId
  );

  return (
    <div className={styles.page}>
      <div className={styles.body}>
        <div className={`${styles.header} ${styles.marginBottom2}`}>
          <h1>{props.accountingFirm.name}</h1>
        </div>
        <div className={`${styles.header} ${styles.marginBottom}`}>
          <h2>{translations.accountants}</h2>
          <div className={styles.actions}>
            <Button
              onClick={() => {
                setSelectedAccountantId(undefined);
                setIsCreatingNewAccountant(true);
              }}
              icon="pi pi-user-plus"
              label={translations.newAccountant}
            />
          </div>
        </div>
        <AccountantsTable
          accountants={props.accountants}
          selectedAccountant={selectedAccountant}
          onAccountantSelect={(accountantId) => {
            setSelectedAccountantId(accountantId);
            setIsCreatingNewAccountant(false);
          }}
        />
      </div>
      {(() => {
        if (isCreatingNewAccountant) {
          return (
            <div className={styles.detailsPanel}>
              <DetailsPanel
                selectedAccountant={undefined}
                onCancel={() => {
                  setSelectedAccountantId(undefined);
                  setIsCreatingNewAccountant(false);
                }}
                onAccountantCreated={(accountant) => {
                  setSelectedAccountantId(accountant.id);
                  setIsCreatingNewAccountant(false);
                  props.onAccountantCreated(accountant);
                }}
                onAccountantUpdated={props.onAccountantUpdated}
              />
            </div>
          );
        } else if (selectedAccountant) {
          return (
            <div className={styles.detailsPanel}>
              <DetailsPanel
                selectedAccountant={selectedAccountant}
                onCancel={() => {
                  setSelectedAccountantId(undefined);
                  setIsCreatingNewAccountant(false);
                }}
                onAccountantCreated={(accountant) => {
                  setSelectedAccountantId(accountant.id);
                  setIsCreatingNewAccountant(false);
                  props.onAccountantCreated(accountant);
                }}
                onAccountantUpdated={props.onAccountantUpdated}
              />
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </div>
  );
}

export function AccountingFirmPage() {
  const toast = useRef<Toast | null>(null);

  return (
    <>
      <Toast ref={toast} />
      <AccountingFirmLoader toast={toast}>
        {({ accountingFirm }) => {
          return (
            <AccountantsLoader toast={toast}>
              {({ accountants, onAccountantCreated, onAccountantUpdated }) => {
                return (
                  <_AccountingFirmPage
                    accountingFirm={accountingFirm}
                    accountants={accountants}
                    onAccountantCreated={onAccountantCreated}
                    onAccountantUpdated={onAccountantUpdated}
                  />
                );
              }}
            </AccountantsLoader>
          );
        }}
      </AccountingFirmLoader>
    </>
  );
}
