import { useState } from "react";
import { CreateFinApiItem } from "./create-finapi-item";
import { ListFinApiItems } from "./list-finapi-accounts";
import styles from "./finapi.module.scss";
import { Divider } from "primereact/divider";
import { CommunicationError } from "../../../../communication-errors/communication-errors";
import { z } from "zod";

export const BankAccountSchema = z.object({
  account_iban: z.string().nullable(),
  account_name: z.string().nullable(),
});

export const BankConnectionSchema = z.object({
  bank_connection_id: z.number(),
  bank_name: z.string().nullable(),
  bank_logo_url: z.string().nullable(),
  accounts: z.array(BankAccountSchema),
});
export const FinApiResponseSchema = z.object({
  status: z.literal(200),
  body: z.array(BankConnectionSchema),
});

export type BankAccount = z.infer<typeof BankAccountSchema>;
export type BankConnection = z.infer<typeof BankConnectionSchema>;

export type FinApiState = {
  loading?: boolean;
  error?: CommunicationError;
  data?: BankConnection[];
};

export function FinApi() {
  const [state, setState] = useState<FinApiState>({});

  return (
    <>
      <div className={styles.container}>
        <ListFinApiItems finApiState={state} setFinApiState={setState} />
        <Divider />
        <CreateFinApiItem finApiState={state} setFinApiState={setState} />
      </div>
    </>
  );
}
