import { useEffect } from "react";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import styles from "./filters.module.scss";
import {
  FiltersSchema,
  Filters as FiltersType,
  BankTransactionsPageFiltersContext,
} from "./filters-context";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { useContext } from "react";
import { throwError } from "../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  typeLabel: "Art",
  all: "Alle",
  debit: "Soll",
  credit: "Haben",
  accountLabel: "Konto",
  amountLabel: "Betrag",
  min: "Min",
  max: "Max",
  dueDateLabel: "Fälligkeitsdatum",
  from: "Von",
  to: "Bis",
  search: "Suchen",
};

const ENGLISH_TRANSLATIONS = {
  typeLabel: "Type",
  all: "All",
  debit: "Debit",
  credit: "Credit",
  accountLabel: "Account",
  amountLabel: "Amount",
  min: "Min",
  max: "Max",
  dueDateLabel: "Due date",
  from: "From",
  to: "To",
  search: "Search",
};

export function Filters() {
  const { filters, setFilters } =
    useContext(BankTransactionsPageFiltersContext) || throwError();

  const form = useForm({
    resolver: zodResolver(FiltersSchema),
    mode: "onChange",
    defaultValues: filters,
  });

  useEffect(() => {
    form.reset(filters);
  }, [filters, form]);

  const onSubmit = (values: FiltersType) => {
    setFilters(values);
  };

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <div className={styles.filter}>
        <b className={styles.filterLabel}>{translations.typeLabel}</b>
        <Controller
          name="entry_type"
          control={form.control}
          render={({ field }) => (
            <div className={styles.radioGroup}>
              <div className={styles.radioWrapper}>
                <RadioButton
                  id="entry_type_all"
                  value="all"
                  checked={field.value === "all"}
                  onChange={field.onChange}
                  className={styles.radioButton}
                />
                <label htmlFor="entry_type_all">{translations.all}</label>
              </div>
              <div className={styles.radioWrapper}>
                <RadioButton
                  id="entry_type_debit"
                  value="debit"
                  checked={field.value === "debit"}
                  onChange={field.onChange}
                  className={styles.radioButton}
                />
                <label htmlFor="entry_type_debit">{translations.debit}</label>
              </div>
              <div className={styles.radioWrapper}>
                <RadioButton
                  id="entry_type_credit"
                  value="credit"
                  checked={field.value === "credit"}
                  onChange={field.onChange}
                  className={styles.radioButton}
                />
                <label htmlFor="entry_type_credit">{translations.credit}</label>
              </div>
            </div>
          )}
        />
      </div>
      <div className={styles.filter}>
        <b className={styles.filterLabel}>{translations.accountLabel}</b>
        <Controller
          name="merchant_name"
          control={form.control}
          render={({ field, fieldState }) => {
            return (
              <div className={`${styles.inputGroup}`}>
                <InputText
                  className={styles.input}
                  id="merchant-name"
                  value={field.value ?? ""}
                  onChange={(e) => field.onChange(e.target.value || null)}
                />
                {fieldState.error ? (
                  <small className="p-error">{fieldState.error.message}</small>
                ) : null}
              </div>
            );
          }}
        />
      </div>
      <div className={styles.filter}>
        <b className={styles.filterLabel}>{translations.amountLabel}</b>
        <Controller
          name="amount_min"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <label className="p-inputgroup-addon" htmlFor="amount-min">
                  <small>{translations.min}</small>
                </label>
                <InputNumber
                  className={styles.input}
                  id="amount-min"
                  value={field.value}
                  onValueChange={(e) => field.onChange(e.target.value || null)}
                  locale={navigator.language}
                  maxFractionDigits={2}
                />
              </div>
            );
          }}
        />
        <Controller
          name="amount_max"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <label className="p-inputgroup-addon" htmlFor="amount-max">
                  <small>{translations.max}</small>
                </label>
                <InputNumber
                  className={styles.input}
                  id="amount-max"
                  value={field.value}
                  onValueChange={(e) => field.onChange(e.target.value || null)}
                  locale={navigator.language}
                  maxFractionDigits={2}
                />
              </div>
            );
          }}
        />
      </div>
      <div className={styles.filter}>
        <b className={styles.filterLabel}>{translations.dueDateLabel}</b>
        <Controller
          name="created_at_min"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <>
                  <label className="p-inputgroup-addon" htmlFor="due-min">
                    <small>{translations.from}</small>
                  </label>
                  <Calendar
                    className={styles.input}
                    value={field.value}
                    onChange={field.onChange}
                    inputId="due-min"
                  />
                </>
              </div>
            );
          }}
        />

        <Controller
          name="created_at_max"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <label className="p-inputgroup-addon" htmlFor="due-max">
                  <small>{translations.to}</small>
                </label>
                <Calendar
                  className={styles.input}
                  value={field.value}
                  onChange={field.onChange}
                  inputId="due-max"
                />
              </div>
            );
          }}
        />
      </div>

      <Button
        type="submit"
        className={styles.submit}
        icon="pi pi-search"
        label={translations.search}
      />
    </form>
  );
}
