import { useState, useEffect } from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../../../main-api";
import { CommunicationError } from "../../../../../../../communication-errors/communication-errors";
import { DataView } from "primereact/dataview";
import { Badge } from "primereact/badge";
import { useAccountingClientId } from "../../../accounting-client-id";
import styles from "./gdpdu-uploads.module.scss";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { ProgressSpinner } from "primereact/progressspinner";

const GERMAN_TRANSLATIONS = {
  date: "Datum",
  processing: "Verarbeitung",
  success: "Erfolg",
  withIssues: "Mit Problemen",
  issues: "Probleme",
  clientNumberDoesNotMatch: "Die Kundennummer stimmt nicht überein",
  systemOfAccountsNumberDoesNotMatch:
    "Die Kontenrahmennummer stimmt nicht überein",
  taxNumberDoesNotMatch: "Die Steuernummer stimmt nicht überein",
  companySuffixDoesNotMatch: "Der Firmenzusatz stimmt nicht überein",
  vatIdDoesNotMatch:
    "Die Umsatzsteuer-Identifikationsnummer stimmt nicht überein",
  noGdpduUploadsFound: "Keine GDPdU-Übertragungen gefunden",
};

const ENGLISH_TRANSLATIONS = {
  date: "Date",
  processing: "Processing",
  success: "Success",
  withIssues: "With Issues",
  issues: "Issues",
  clientNumberDoesNotMatch: "Client number does not match",
  systemOfAccountsNumberDoesNotMatch:
    "System of accounts number does not match",
  taxNumberDoesNotMatch: "Tax number does not match",
  companySuffixDoesNotMatch: "Company suffix does not match",
  vatIdDoesNotMatch: "VAT ID does not match",
  noGdpduUploadsFound: "No GDPdU uploads found",
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

const GdpduIssueSchema = z.object({
  id: z.string(),
  type: z.enum([
    "ClientNumberDoesNotMatch",
    "SystemOfAccountsNumberDoesNotMatch",
    "TaxNumberDoesNotMatch",
    "VatIdDoesNotMatch",
  ]),
});

export type GdpduIssue = z.TypeOf<typeof GdpduIssueSchema>;

const GdpduUploadsSchema = z.object({
  id: z.string(),
  file_name: z.string(),
  created_at: z.coerce.date().nullable(),
  status: z.enum(["PROCESSING", "SUCCESS", "WITH_ISSUES"]),
  issues: z.array(GdpduIssueSchema),
});

export type GdpduUpload = z.TypeOf<typeof GdpduUploadsSchema>;

export default function GdpduUploads(props: { forcedUpdateKey: number }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();
  const [results, setResults] = useState<
    Readonly<{
      data?: {
        rows: GdpduUpload[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  useEffect(() => {
    const fetchGdpduUploads = async () => {
      setResults({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${accountingClientId}/gdpdu_uploads`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            gdpdu_uploads: z.array(GdpduUploadsSchema),
          }),
        }),
      });

      if (res.error) {
        setResults({ error: res.error });
        return;
      }

      setResults({
        data: {
          rows: res.response.body.gdpdu_uploads,
        },
      });
    };

    fetchGdpduUploads();
  }, [accountingClientId, mainApi, props.forcedUpdateKey]);

  const data = results.data;

  if (!data) {
    if (results.loading) {
      return <ProgressSpinner />;
    }
    return;
  }

  return (
    <>
      <DataView
        value={data?.rows}
        itemTemplate={(row: GdpduUpload) => (
          <div key={row.id} className={styles.rowItem}>
            <div className={styles.rowItemHeader}>
              <div className={styles.rowItemHeaderSection}>
                <b className={styles.fileName}>{row.file_name}</b>
              </div>
              <div className={styles.rowItemHeaderSection}>
                <Badge
                  severity={(() => {
                    if (row.status === "PROCESSING") return "info";
                    if (row.status === "SUCCESS") return "success";
                    return "danger";
                  })()}
                  size={"normal"}
                />
                <span>
                  {(() => {
                    if (row.status === "PROCESSING")
                      return translations.processing;
                    if (row.status === "SUCCESS") return translations.success;
                    return translations.withIssues;
                  })()}
                </span>
              </div>
              <div
                className={`${styles.rowItemHeaderSection} ${styles.justifyContentFlexEnd}`}
              >
                {row.created_at ? (
                  <span>
                    <b>{translations.date}:</b>{" "}
                    {dateFormatter.format(row.created_at)}
                  </span>
                ) : null}
              </div>
            </div>
            <div className={styles.rowItemContent}>
              {row.issues && row.issues.length > 0 && (
                <div className={styles.rowItemIssues}>
                  <b>{translations.issues}:</b>

                  {row.issues.map((issue) => (
                    <div key={issue.id} className={styles.rowItemIssue}>
                      <span className={styles.textErrorColor}>
                        {(() => {
                          if (issue.type === "ClientNumberDoesNotMatch") {
                            return translations.clientNumberDoesNotMatch;
                          } else if (
                            issue.type === "SystemOfAccountsNumberDoesNotMatch"
                          ) {
                            return translations.systemOfAccountsNumberDoesNotMatch;
                          } else if (issue.type === "TaxNumberDoesNotMatch") {
                            return translations.taxNumberDoesNotMatch;
                          } else if (issue.type === "VatIdDoesNotMatch") {
                            return translations.vatIdDoesNotMatch;
                          } else {
                            throw new Error();
                          }
                        })()}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        className={styles.dataView}
        emptyMessage={translations.noGdpduUploadsFound}
      />
    </>
  );
}
