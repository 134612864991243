import { ReactNode, createContext, useMemo, useState, useContext } from "react";
import { z } from "zod";
import { useAccountingClientId } from "../../../../accounting-client-id";
import { BookingsPageResourcesContext } from "../../../page-resources";
import { throwError } from "../../../../../../../../throw-error";

export const BankAccountSchema = z.object({
  id: z.string(),
  account_id: z.string(),
  account_number: z.number(),
  account_name: z.string(),
});

export type BankAccount = z.TypeOf<typeof BankAccountSchema>;

function useContextValue() {
  const accountingClientId = useAccountingClientId();

  const resources = useContext(BookingsPageResourcesContext) || throwError();

  const [selectedBankAccount, setSelectedBankAccount] = useState<
    BankAccount | undefined
  >(resources.bank_accounts[0]);

  return useMemo(() => {
    return {
      accountingClientId,
      selectedBankAccount,
      setSelectedBankAccount,
    };
  }, [accountingClientId, selectedBankAccount, setSelectedBankAccount]);
}

export const BookingsPageBankAccountsContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function BookingsPageBankAccountsProvider(props: {
  children: ReactNode;
}) {
  const value = useContextValue();

  return (
    <BookingsPageBankAccountsContext.Provider value={value}>
      {props.children}
    </BookingsPageBankAccountsContext.Provider>
  );
}
