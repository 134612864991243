import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { useCallback, useContext, useMemo } from "react";
import { throwError } from "../../../../../../throw-error";
import { Booking, ResultsContext } from "./results-context";
import styles from "./results.module.scss";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { SortColumn, SortingContext } from "../sorting/sorting-context";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../react-data-grid";
import { ProgressSpinner } from "primereact/progressspinner";

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});
const GERMAN_TRANSLATIONS = {
  transactionDate: "Transaktionsdatum",
  amount: "Betrag",
  counterAccount: "Gegenseite",
  applicantName: "Name",
  applicantIban: "IBAN",
  purpose: "Zweck",
  description: "Beschreibung",
  issues: "Probleme",
  status: "Status",
  actionNeeded: "Aktion Erforderlich",
  correctedByAccountant: "Vom Buchhalter Korrigiert",
  predictedWithoutIssues: "Verarbeitet",
  historical: "Historisch",
  unableToPredict: "Keine verlässliche Vorhersage möglich",
  unreliablePredictionTitle: "Keine zuverlässige Vorhersage möglich",
  couldNotPredictTaxCodeTitle: "Kann Steuercodes nicht vorhersagen",
  multipleTaxCodesTitle: "Mehrere Steuercodes",
  multipleTaxCodesDescription:
    "Es gibt viele moegliche Steuercodes für diese Buchung:",
  noResultsFound: "Keine Ergebnisse gefunden.",
};

const ENGLISH_TRANSLATIONS = {
  transactionDate: "Transaction Date",
  amount: "Amount",
  counterAccount: "Counter Account",
  applicantName: "Applicant Name",
  applicantIban: "Applicant IBAN",
  purpose: "Purpose",
  description: "Description",
  issues: "Issues",
  status: "Status",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  predictedWithoutIssues: "Processed",
  historical: "Historical",
  unableToPredict: "Unable to make a reliable prediction",
  unreliablePredictionTitle: "Unable to make a reliable prediction",
  couldNotPredictTaxCodeTitle: "Could not predict tax code",
  multipleTaxCodesTitle: "Multiple Tax Codes",
  multipleTaxCodesDescription:
    "There are many possible tax codes for this booking:",
  noResultsFound: "No results found.",
};
export function Results() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { results, setSelectedBookingId, setOffset } =
    useContext(ResultsContext) || throwError();

  const sorting = useContext(SortingContext) || throwError();

  const getRowKey = useCallback(
    (r: Partial<Booking>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn(sortColumn as SortColumn | undefined);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return sorting.sortColumn ? [sorting.sortColumn] : [];
  }, [sorting]);

  const columns: Column<Booking>[] = useMemo(() => {
    const _columns: Column<Booking>[] = [
      {
        key: "transaction_date",
        name: translations.transactionDate,
        width: 200,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.transaction_date;
          return dateFormatter.format(value);
        },
      },
      {
        key: "amount",
        name: translations.amount,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.amount;
          return `€ ${value}`;
        },
        cellClass: (row) => {
          if (row.is_debit) {
            return styles.hasDebit;
          } else {
            return styles.hasCredit;
          }
        },
      },
      {
        key: "counter_account",
        name: translations.counterAccount,
        width: 150,
        resizable: true,
        sortable: true,
        cellClass: (row) => {
          const uniqueClass = `counter-account-cell-${row.id}`;
          return `${uniqueClass}`;
        },
        renderCell: (args) => {
          const uniqueClass = `counter-account-cell-${args.row.id}`;
          if (!args.row.counter_account) {
            return <></>;
          }
          return (
            <>
              <Tooltip
                position={"top"}
                target={`.${uniqueClass}`}
                content={`${args.row.counter_account.name}`}
              />
              <span>{args.row.counter_account.number}</span>
            </>
          );
        },
      },
      {
        key: "applicant_name",
        name: translations.applicantName,
        resizable: true,
        renderCell: (args) => {
          if (!args.row.applicant_name) {
            return <></>;
          }
          return args.row.applicant_name;
        },
      },
      {
        key: "applicant_iban",
        name: translations.applicantIban,
        resizable: true,
        renderCell: (args) => {
          if (!args.row.applicant_iban) {
            return <></>;
          }
          return args.row.applicant_iban;
        },
      },

      {
        key: "description",
        name: translations.description,
        resizable: true,
        cellClass: (row) => {
          const uniqueClass = `description-cell-${row.id}`;
          return `${uniqueClass} ${styles.descriptionCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `description-cell-${args.row.id}`;
          if (!args.row.description) {
            return <></>;
          }
          return (
            <>
              <Tooltip
                position={"top"}
                target={`.${uniqueClass}`}
                content={`${args.row.description}`}
              />
              <span>{args.row.description}</span>
            </>
          );
        },
      },
      {
        key: "issues",
        name: translations.issues,
        frozen: true,
        width: 70,
        cellClass: (row) => {
          const uniqueClass = `issues-cell-${row.id}`;
          return `${uniqueClass} ${styles.issuesCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `issues-cell-${args.row.id}`;
          const openIssues = args.row.issues.filter((issue) => !issue.closed);
          if (openIssues.length === 0) {
            return <></>;
          }
          return (
            <>
              <Tooltip position={"left"} target={`.${uniqueClass}`}>
                <ul>
                  {openIssues.map((i) => (
                    <li key={i.id}>
                      {(() => {
                        if (i.type === "COULD_NOT_PREDICT_TAX_CODE") {
                          return (
                            <span>
                              {translations.couldNotPredictTaxCodeTitle}
                            </span>
                          );
                        } else if (i.type === "MULTIPLE_TAX_CODES") {
                          return (
                            <span>{translations.multipleTaxCodesTitle}</span>
                          );
                        } else if (i.type === "UNRELIABLE_PREDICTION") {
                          return <span>{i.description}</span>;
                        } else {
                          throw new Error();
                        }
                      })()}
                    </li>
                  ))}
                </ul>
              </Tooltip>
              <Badge value={openIssues.length} />
            </>
          );
        },
      },
      {
        key: "status",
        name: translations.status,
        frozen: true,
        width: 20,
        renderHeaderCell: () => {
          return <></>;
        },
        cellClass: (row) => {
          const uniqueClass = `status-cell-${row.id}`;
          return `${uniqueClass} ${styles.statusCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `status-cell-${args.row.id}`;
          return (
            <>
              <Tooltip
                position={"left"}
                target={`.${uniqueClass}`}
                content={(() => {
                  if (args.row.status === "ACTION_NEEDED") {
                    return translations.actionNeeded;
                  } else if (args.row.status === "CORRECTED_BY_ACCOUNTANT") {
                    return translations.correctedByAccountant;
                  } else if (args.row.status === "PREDICTED_WITHOUT_ISSUES") {
                    return translations.predictedWithoutIssues;
                  } else if (args.row.status === "HISTORICAL") {
                    return translations.historical;
                  } else {
                    throw new Error();
                  }
                })()}
              />
              <Badge
                className={`${
                  args.row.status === "HISTORICAL"
                    ? styles.historicalStatusBadge
                    : ""
                }`}
                size={"normal"}
                severity={(() => {
                  if (args.row.status === "ACTION_NEEDED") {
                    return "danger";
                  } else if (args.row.status === "CORRECTED_BY_ACCOUNTANT") {
                    return "info";
                  } else if (args.row.status === "PREDICTED_WITHOUT_ISSUES") {
                    return "success";
                  } else if (args.row.status === "HISTORICAL") {
                    return "success";
                  } else {
                    throw new Error();
                  }
                })()}
              />
            </>
          );
        },
      },
    ];

    return _columns;
  }, [translations]);

  const data = results.data;

  if (!data) {
    if (results.loading) {
      return <ProgressSpinner className={styles.page} />;
    }

    return <></>;
  }

  if (data.rows.length == 0) {
    return (
      <div className={styles.page}>
        <span className={styles.noBookings}>{translations.noResultsFound}</span>
      </div>
    );
  }

  return (
    <>
      <ReactDataGrid
        className={`${styles.table}`}
        rows={data.rows}
        columns={columns}
        rowKeyGetter={getRowKey}
        onSelectedCellChange={(args) => {
          if (!args.row) {
            return;
          }

          setSelectedBookingId(args.row.id);
        }}
        onWheelAndBottom={() => {
          console.log(data.rows.length);
          setOffset(data.rows.length);
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
      />

      {results.loading && (
        <div className={styles.loadingMore}>Loading more rows...</div>
      )}
    </>
  );
}
