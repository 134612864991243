import { Calendar } from "primereact/calendar";
import styles from '../navbar.module.scss';
import { AccountingClientDropdown } from "../../accounting-clients/accounting-clients-dropdown";
import { useContext } from "react";
import { QuickFiltersContext } from "./quick-filters-context";
import { throwError } from "../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
    from: "Von",
    to: "Bis",
};

const ENGLISH_TRANSLATIONS = {
    from: "From",
    to: "To",
};

export function QuickFilters() {
    const { quickFilters, setQuickFilters } =
        useContext(QuickFiltersContext) || throwError();

    const currentLanguage = useCurrentLanguage();
    const translations =
        currentLanguage === SupportedLanguage.German
            ? GERMAN_TRANSLATIONS
            : ENGLISH_TRANSLATIONS;

    return (
        <div className={styles.filterBar}>
            <div className={styles.filterGroup}>
                <div className={styles.filterOptions}>
                    <AccountingClientDropdown className={styles.dropdown} />
                </div>

                <div className={styles.filterOptions}>
                    <Calendar
                        value={quickFilters.time_frame_from}
                        onChange={(e) => {
                            setQuickFilters((prev) => {
                                return {
                                    ...prev,
                                    time_frame_from: e.value ?? undefined,
                                };
                            });
                        }}
                        dateFormat="dd MM yy"
                        placeholder={translations.from}
                        showIcon
                        showButtonBar
                    />
                </div>
                <div className={styles.filterOptions}>
                    <Calendar
                        value={quickFilters.time_frame_to}
                        onChange={(e) => {
                            setQuickFilters((prev) => {
                                return {
                                    ...prev,
                                    time_frame_to: e.value ?? undefined,
                                };
                            });
                        }}
                        dateFormat="dd MM yy"
                        placeholder={translations.to}
                        showIcon
                        showButtonBar
                    />
                </div>
            </div>
        </div>
    );
}
