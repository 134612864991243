import { useMainApi } from "../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../communication-errors/communication-error-messages";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { z } from "zod";
import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { FinApiState, BankConnectionSchema } from "./finapi";
import styles from "./create-finapi-item.module.scss";
import { throwError } from "../../../../throw-error";

const GERMAN_TRANSLATIONS = {
  accountsConnected: "Konten erfolgreich verbunden",
  requestToAddNewBank: "Anfrage zum Hinzufügen einer neuen Bank",
  requestCompleted: "Anfrage abgeschlossen, klicken Sie hier, um fortzufahren",
  bankAlreadyConnectedSummary: "Bank ist bereits verbunden",
  doNotCloseTabWarning: "Schließen Sie diese Registerkarte nicht",
};

const ENGLISH_TRANSLATIONS = {
  accountsConnected: "Accounts successfully connected",
  requestToAddNewBank: "Request to add new bank",
  requestCompleted: "Request completed, click here to continue",
  bankAlreadyConnectedSummary: "Bank is already connected",
  doNotCloseTabWarning: "Do not close this tab while connecting your bank.",
};

export function CreateFinApiItem({
  finApiState,
  setFinApiState,
}: {
  finApiState: FinApiState;
  setFinApiState: Dispatch<SetStateAction<FinApiState>>;
}) {
  const toast = useRef<Toast | null>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();
  const [state, setState] = useState<{
    loading?: boolean;
    data?: {
      webformId?: string;
      webformUrl?: string;
    };
  }>({});
  const [dialogVisible, setDialogVisible] = useState(false);

  const pollWebFormStatus = useCallback(
    async (webFormId: string) => {
      setDialogVisible(true);

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const res = await mainApi.fetchJSON({
          path: `/bank-accounts/finapi/web_forms/${webFormId}`,
          method: "GET",
          schema: z.union([
            z.object({
              status: z.literal(200),
              body: z.object({
                status: z.literal("COMPLETED"),
                bank_connection: BankConnectionSchema,
              }),
            }),
            z.object({
              status: z.literal(200),
              body: z.object({
                status: z.literal("IN_PROGRESS"),
              }),
            }),
            z.object({
              status: z.literal(200),
              body: z.object({
                status: z.literal("ENTITY_EXISTS"),
              }),
            }),
          ]),
        });

        if (res.error) {
          setDialogVisible(false);
          toast.current?.show({
            severity: "error",
            summary: getCommunicationErrorTitle(res.error),
            detail: getCommunicationErrorMessage(res.error),
          });
          return;
        }

        const responseBody = res.response.body;

        if (responseBody.status === "COMPLETED") {
          setDialogVisible(false);

          setFinApiState((prevState) => {
            if (!prevState.data) {
              throw new Error();
            }

            return {
              ...prevState,
              data: [...prevState.data, responseBody.bank_connection],
            };
          });

          toast.current?.show({
            severity: "success",
            summary: translations.accountsConnected,
          });

          return;
        } else if (responseBody.status === "ENTITY_EXISTS") {
          setDialogVisible(false);
          toast.current?.show({
            severity: "warn",
            summary: translations.bankAlreadyConnectedSummary,
            life: 30 * 1000,
          });
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    },
    [mainApi, setFinApiState, translations]
  );

  return (
    <>
      <Toast ref={toast} />
      <Dialog visible={dialogVisible} onHide={() => {}} closable={false}>
        <div className={`${styles.loading} ${styles.dialogcenter}`}>
          <ProgressSpinner />
          <b>{translations.doNotCloseTabWarning}</b>
        </div>
      </Dialog>
      <div className={styles.center}>
        {!state.data ? (
          <Button
            outlined={true}
            icon={"pi pi-link"}
            label={`${translations.requestToAddNewBank}`}
            disabled={state.loading || !finApiState.data}
            loading={state.loading}
            onClick={async () => {
              setState({ loading: true });

              const res = await mainApi.fetchJSON({
                method: "POST",
                path: "/bank-accounts/finapi/accounting-client/connect-new-bank",
                schema: z.object({
                  status: z.literal(200),
                  body: z.object({
                    web_form_url: z.string(),
                    web_form_id: z.string(),
                  }),
                }),
              });

              setState({ loading: false });

              if (res.error) {
                toast.current?.show({
                  severity: "error",
                  summary: getCommunicationErrorTitle(res.error),
                  detail: getCommunicationErrorMessage(res.error),
                });
                return;
              }

              setState({
                data: {
                  webformId: res.response.body.web_form_id,
                  webformUrl: res.response.body.web_form_url,
                },
              });
            }}
          />
        ) : null}
        {state.data ? (
          <a
            className={"p-button"}
            onClick={() => {
              pollWebFormStatus(state.data?.webformId || throwError());
            }}
            href={state.data.webformUrl}
            target="_blank"
            rel="noreferrer"
          >
            <span className={"pi pi-arrow-up-right"}></span>

            {translations.requestCompleted}
          </a>
        ) : null}
      </div>
    </>
  );
}
