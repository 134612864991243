import { useMemo } from "react";
import { useParams } from "react-router-dom";

export function useAccountingClientId() {
  const params = useParams();

  const accountingClientId = (() => {
    const accountingClientId = params.accountingClientId;

    if (typeof accountingClientId !== "string") {
      throw new Error();
    }

    return accountingClientId;
  })();

  return useMemo(() => {
    return accountingClientId
  }, [accountingClientId])
}