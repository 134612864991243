import { ReactNode } from "react";
import styles from "./detail.module.scss";

export function Detail(props: { label: string; children: ReactNode }) {
  return (
    <div className={styles.detail}>
      <p className={styles.marginBottom}>{props.label}</p>
      <div className={styles.valueWrapper}>
        <div className={styles.width100}>{props.children}</div>
      </div>
    </div>
  );
}
