import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Redirect(props: { url: string }) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(props.url)
  }, [navigate, props.url])
  
  return <></>;
}