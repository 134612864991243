import { useRef, useState } from "react";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../main-api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Toast } from "primereact/toast";
import styles from "./styles.module.scss";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { TERMS_AND_CONDITIONS_ROUTE } from "../../../terms-and-conditions/terms_and_conditions_route";
import { PRIVACY_POLICY_ROUTE } from "../../../privacy-policy/privacy-policy-routes";

const GERMAN_TRANSLATIONS = {
  newPassword: "Ihr neues Passwort",
  confirmAndCreateAccount: "Bestätigen und Konto erstellen",
  acceptTerms: "Ich akzeptiere die ",
  termsAndConditions: "Nutzungsbedingungen",
  privacyPolicy: "Datenschutzbestimmungen",
  acceptPrivacyPolicy: "Ich akzeptiere die ",
};

const ENGLISH_TRANSLATIONS = {
  newPassword: "Your new password",
  confirmAndCreateAccount: "Confirm and create account",
  acceptTerms: "I accept the ",
  termsAndConditions: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  acceptPrivacyPolicy: "I accept the ",
};

export function AcceptInviteForm(props: { onFinish: () => void }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();
  const toastRef = useRef<Toast | null>(null);

  const form = useForm({
    resolver: zodResolver(
      z.object({
        password: z
          .string()
          .min(8, "Must contain at least 8 character(s)")
          .max(32, "Must contain at most 32 character(s)"),
        accepted_terms_and_conditions: z
          .boolean()
          .refine(
            (value) => value === true,
            "You must agree to the Terms and Conditions"
          ),
        accepted_privacy_policy: z
          .boolean()
          .refine(
            (value) => value === true,
            "You must agree to the Privacy Policy"
          ),
      })
    ),
    defaultValues: {
      password: "",
      accepted_terms_and_conditions: false,
      accepted_privacy_policy: false,
    },
  });

  const [state, setState] = useState<{
    loading?: boolean;
  }>({});

  return (
    <>
      <Toast ref={toastRef} />
      <h1 className={styles.marginBottomSpacer2}>Accept invite</h1>
      <Card>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            setState({ loading: true });

            const token = new URLSearchParams(window.location.search).get(
              "token"
            );

            if (!token) {
              toastRef.current?.show({
                severity: "error",
                summary: "Error",
                detail: getCommunicationErrorMessage(
                  CommunicationError.UnexpectedResponse
                ),
              });
              return;
            }

            const res = await mainApi.fetchJSON({
              path: `/mobile_app_users/accept_invite`,
              method: "POST",
              schema: z.object({
                status: z.literal(200),
              }),
              body: {
                ...data,
                token,
              },
            });

            if (res.error) {
              toastRef.current?.show({
                severity: "error",
                summary: getCommunicationErrorTitle(res.error),
                detail: getCommunicationErrorMessage(res.error),
              });

              return;
            }

            props.onFinish();
          })}
        >
          <h2 className={styles.marginBottomSpacer}>Define your password</h2>
          <div className={styles.marginBottomSpacer2}>
            <Controller
              name="password"
              control={form.control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <div className="p-inputgroup">
                      <InputText
                        placeholder={translations.newPassword}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    </div>
                    {fieldState.error ? (
                      <span className={styles.inputErrorLabel}>
                        {fieldState.error.message}
                      </span>
                    ) : null}
                  </>
                );
              }}
            />
          </div>

          <div className={styles.checkboxWrapper}>
            <Controller
              name="accepted_privacy_policy"
              control={form.control}
              render={({ field, fieldState }) => (
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="accepted_privacy_policy"
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                  <label
                    htmlFor="accepted_privacy_policy"
                    className={styles.label}
                  >
                    {translations.acceptPrivacyPolicy}
                    <Link
                      to={PRIVACY_POLICY_ROUTE.getHref()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translations.privacyPolicy}
                    </Link>
                  </label>
                  {fieldState.error && (
                    <span className={styles.inputErrorLabel}>
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div className={styles.marginBottomSpacer}>
            <Controller
              name="accepted_terms_and_conditions"
              control={form.control}
              render={({ field, fieldState }) => (
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="accepted_terms_and_conditions"
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                  <label
                    htmlFor="accepted_terms_and_conditions"
                    className={styles.label}
                  >
                    {translations.acceptTerms}
                    <Link
                      to={TERMS_AND_CONDITIONS_ROUTE.getHref()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translations.termsAndConditions}
                    </Link>
                  </label>
                  {fieldState.error && (
                    <span className={styles.inputErrorLabel}>
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <Button
            loading={state.loading}
            type="submit"
            label={translations.confirmAndCreateAccount}
            severity={"success"}
          />
        </form>
      </Card>
    </>
  );
}
