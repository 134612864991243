import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import {
  BankAccountWithoutNominalAccount,
  NominalAccountToConnect,
} from "../../bank-information";
import styles from "./bank-account-to-be-connected.module.scss";
import { throwError } from "../../../../../../../../throw-error";

export const BankAccountToBeConnected = ({
  bankAccount,
  nominalAccountsToConnect,
  selectedNominalAccountId,
  onChange,
}: {
  bankAccount: BankAccountWithoutNominalAccount;
  nominalAccountsToConnect: NominalAccountToConnect[];
  selectedNominalAccountId: string | undefined;
  onChange: (
    bankAccountId: string,
    nominalAccountId: string | undefined
  ) => void;
}) => {
  const dropdownOptions = [
    ...nominalAccountsToConnect.map((t) => ({
      value: t.id,
      label: t.account_name,
    })),
  ];

  return (
    <Card>
      <div className={styles.dropdownWrapper}>
        <Dropdown
          className={`${styles.width100}`}
          value={selectedNominalAccountId}
          options={dropdownOptions}
          onChange={(e) => {
            onChange(bankAccount.id, e.value || undefined);
          }}
          placeholder="Select a nominal account"
          optionLabel="label"
          filter={true}
          showClear={true}
          itemTemplate={(option) => {
            const nominalAccount =
              nominalAccountsToConnect.find((a) => a.id === option.value) ??
              throwError();

            return (
              <div className={styles.item}>
                {nominalAccount.account_number} | {nominalAccount.account_name}
              </div>
            );
          }}
          valueTemplate={(option: SelectItem | null, props) => {
            if (!option) {
              return (
                <div className={styles.noneSelected}>{props.placeholder}</div>
              );
            }

            const nominalAccount =
              nominalAccountsToConnect.find((a) => a.id === option.value) ||
              throwError();

            return (
              <div className={styles.item}>
                {nominalAccount.account_number} | {nominalAccount.account_name}
              </div>
            );
          }}
        />
      </div>
      <div className={styles.detail}>
        IBAN
        <b>{bankAccount.iban}</b>
      </div>
    </Card>
  );
};
