import { ReactNode, createContext, useMemo, useState } from "react";

export type QuickFilters = {
  time_frame_from?: Date;
  time_frame_to?: Date;
};
function useProvider() {
  const [quickFilters, setQuickFilters] = useState<QuickFilters>({
    time_frame_from: undefined,
    time_frame_to: undefined,
  });

  return useMemo(() => {
    return {
      quickFilters,
      setQuickFilters,
    };
  }, [quickFilters]);
}

export const QuickFiltersContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function QuickFiltersContextProvider(props: { children: ReactNode }) {
  const value = useProvider();

  return (
    <QuickFiltersContext.Provider value={value}>
      {props.children}
    </QuickFiltersContext.Provider>
  );
}
