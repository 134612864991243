import { useState } from "react";
import { AcceptInviteForm } from "./steps/form/form";
import { AcceptInviteEnd } from "./steps/end/end";
import styles from "./page.module.scss";


export function AcceptInvitePage() {
  const [step, setStep] = useState<"form" | "end">("form");

  return <div className={styles.page}>
    <div className={styles.container}>
    {(() => {
      if(step == "form") {
        return <AcceptInviteForm onFinish={() => setStep("end")} />
      } else if (step == "end") {
        return <AcceptInviteEnd />
      } else {
        throw new Error
      }
    })()}
    </div>
  </div>
  
}