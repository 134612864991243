/* eslint-disable no-console */

import { useMemo } from "react";
import * as Sentry from "@sentry/react";

export type Logger = {
  logError: (error: unknown, data?: unknown) => void;
};

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export function useLogger(): Logger {
  const value = useMemo((): Logger => {
    return {
      logError: (error: unknown, data?: unknown) => {
        if (SENTRY_DSN) {
          Sentry.captureException(error, { extra: { data } });
        } else {
          console.error(error, data);
        }
      },
    };
  }, []);

  return value;
}
