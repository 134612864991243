import { useContext, useEffect } from "react";
import { useMainApi } from "../../main-api";
import { z } from "zod";
import { ClientAuthenticationStateContext } from "./authentication-state";
import { throwError } from "../../throw-error";

export function ClientAuthEffects() {
  const mainApi = useMainApi();
  const { sessionState, setSessionState } =
    useContext(ClientAuthenticationStateContext) || throwError();

  useEffect(() => {
    (async () => {
      if (
        sessionState.data === undefined &&
        !sessionState.loading &&
        !sessionState.error
      ) {
        setSessionState({ loading: true });

        const res = await mainApi.fetchJSON({
          path: "/mobile_app_users/session",
          method: "GET",
          schema: z.union([
            z.object({
              status: z.literal(404),
            }),
            z.object({
              status: z.literal(200),
              body: z.object({
                user_id: z.string(),
              }),
            }),
          ]),
        });

        if (res.error) {
          setSessionState({
            error: res.error,
          });

          return;
        }

        if (res.response.status === 404) {
          setSessionState({ data: null });

          return;
        }

        setSessionState({ data: res.response.body });
      }
    })();
  }, [sessionState, mainApi, setSessionState]);

  return <></>;
}
