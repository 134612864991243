import { useState } from "react";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { EditAccountDetails } from "./edit-account-details";
import { PersonalAccount } from "./results-context";
import { useCurrentLanguage } from "../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../language/supported-languages";
import { Toast } from "primereact/toast";

const GERMAN_TRANSLATIONS = {
  edit: "Bearbeiten",
};

const ENGLISH_TRANSLATIONS = {
  edit: "Edit",
};

export function ContextMenu(props: {
  selectedAccount: PersonalAccount | undefined;
  accountingClientId: string;
  menuRef: React.RefObject<Menu>;
  toastRef: React.RefObject<Toast>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [dialogVisible, setDialogVisible] = useState(false);

  const items: MenuItem[] = [
    {
      label: `${translations.edit}`,
      command: () => {
        setDialogVisible(true);
      },
    },
  ];

  return (
    <>
      <Menu model={items} popup ref={props.menuRef} popupAlignment="right" />
      {props.selectedAccount && (
        <EditAccountDetails
          selectedAccount={props.selectedAccount}
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          accountingClientId={props.accountingClientId}
          toastRef={props.toastRef}
        />
      )}
    </>
  );
}
