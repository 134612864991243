import { useContext } from "react";
import { getCommunicationErrorMessage } from "../../communication-errors/communication-error-messages";
import { Redirect } from "../../navigation/redirect";
import { ProgressSpinner } from "primereact/progressspinner";
import { AccountantAuthenticationStateContext } from "./authentication-state";
import { throwError } from "../../throw-error";

export function AccountantAuthenticatedPage(props: {
  children: React.ReactNode;
}) {
  const { sessionState } =
    useContext(AccountantAuthenticationStateContext) || throwError();

  if (sessionState.data === undefined) {
    if (sessionState.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressSpinner />
        </div>
      );
    }

    if (sessionState.error) {
      const errorMessage = getCommunicationErrorMessage(sessionState.error);
      return <div>{errorMessage}</div>;
    }

    return null;
  }

  if (sessionState.data === null) {
    return <Redirect url="/accountant/login" />;
  }

  if (sessionState.data) {
    return <>{props.children}</>;
  }

  throw new Error();
}
