import { ReactNode, useEffect, useRef, useState } from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../../main-api";
import { useAccountingClientId } from "../../accounting-client-id";
import { Toast } from "primereact/toast";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { throwError } from "../../../../../../throw-error";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { ProgressSpinner } from "primereact/progressspinner";
import styles from "./bank-accounts-loader.module.scss";

export const BankAccountSchema = z.object({
  id: z.string(),
  iban: z.string(),
  linked_to_plaid: z.boolean(),
});

export type BankAccount = z.TypeOf<typeof BankAccountSchema>;

export function BankAccountsLoader(props: {
  children: (props: { bank_accounts: BankAccount[] }) => ReactNode;
}) {
  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();
  const toastRef = useRef<Toast | null>(null);

  const [results, setResults] = useState<
    Readonly<{
      data?: BankAccount[];
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  useEffect(() => {
    (async () => {
      setResults({ loading: true });

      const urlSearchParams = new URLSearchParams({
        accounting_client_id: accountingClientId,
      });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/bank_accounts?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.array(BankAccountSchema),
        }),
      });

      if (res.error) {
        const toast = toastRef.current ?? throwError();
        toast.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        return;
      }

      setResults({ data: res.response.body });
    })();
  }, [accountingClientId, mainApi]);

  return (
    <>
      <Toast ref={toastRef} />

      {(() => {
        if (results.loading) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (!results.data) {
          return <></>;
        }

        return <>{props.children({ bank_accounts: results.data })}</>;
      })()}
    </>
  );
}
