import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { throwError } from "../../../../../../../throw-error";
import { Booking, ResultsContext } from "../../results/results-context";

export function Actions({
  selectedBooking,
  isSubmitting,
}: {
  selectedBooking: Booking;
  isSubmitting: boolean;
}) {
  const { setSelectedBookingId } = useContext(ResultsContext) || throwError();

  return (
    <div className={styles.actionBar}>
      <Button
        icon="pi pi-times"
        severity="danger"
        label="Cancel"
        type="button"
        onClick={() => setSelectedBookingId(undefined)}
      />
      <Button
        icon="pi pi-check"
        severity="success"
        label="Apply"
        className={styles.actionButton}
        disabled={isSubmitting || selectedBooking.status == "HISTORICAL"}
        loading={isSubmitting}
        type="submit"
      />
    </div>
  );
}
