import { ReactNode, createContext, useState } from "react";
import { DEFAULT_SORT_COLUMN } from "./sorting-constants";

export type SortColumn = {
  columnKey: "account_number" | "account_name" | "iban" | "total_transactions";
  direction: "ASC" | "DESC";
};

export type SortingContextType = {
  sortColumn: SortColumn;
  setSortColumn: (column: SortColumn) => void;
};

function useContextValue() {
  const [sortColumn, setSortColumn] = useState<SortColumn>(DEFAULT_SORT_COLUMN);

  return {
    sortColumn,
    setSortColumn,
  };
}

export const BankAccountsSortingContext = createContext<
  ReturnType<typeof useContextValue> | undefined
>(undefined);

export function BankAccountsSortingContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useContextValue();
  return (
    <BankAccountsSortingContext.Provider value={value}>
      {children}
    </BankAccountsSortingContext.Provider>
  );
}
