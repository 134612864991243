import { Badge } from "primereact/badge";
import styles from "./counts.module.scss";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { useMemo } from "react";
import { JournalCount } from "../../result-context";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { useCurrentLanguage } from "../../../../../../../language/current-language";

const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
  maximumFractionDigits: 1,
});

const GERMAN_TRANSLATIONS = {
  totalExportedBookings: "Gesamtanzahl exportierter Buchungen",
  fullyAutomated: "Vollautomatisiert",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Vom Buchhalter korrigiert",
  historical: "Historisch",
};

const ENGLISH_TRANSLATIONS = {
  totalExportedBookings: "Total No. of Exported Bookings",
  fullyAutomated: "Fully Automated",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  historical: "Historical",
};

export function Counts({ journalCounts }: { journalCounts: JournalCount }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const percentage = useMemo(() => {
    // To avoid Infinity NaN error caused by 0/0
    const safeDivide = (numerator: number): number => {
      return journalCounts.total !== 0
        ? Math.ceil((numerator / journalCounts.total) * 100)
        : 0;
    };

    return {
      historical_reduced_journal_percentage: safeDivide(
        journalCounts.historical_reduced_journals_count
      ),
      action_needed_reduced_journal_percentage: safeDivide(
        journalCounts.action_needed_reduced_journals_count
      ),
      predicted_reduced_journal_percentage: safeDivide(
        journalCounts.predicted_reduced_journals_count
      ),
      corrected_reduced_journal_percentage: safeDivide(
        journalCounts.corrected_reduced_journals_count
      ),
    };
  }, [journalCounts]);

  return (
    <Card className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.summary}>
          <Badge severity={"success"} className={styles.total} />
          <div className={styles.summaryDetail}>
            <span>{translations.totalExportedBookings}</span>
            <span className={styles.cardLabels}>
              {numberFormatter.format(journalCounts.total)}
            </span>
          </div>
        </div>
        <Divider layout="vertical" />
        <div className={styles.summary}>
          <Badge severity={"success"} />
          <div className={styles.summaryDetail}>
            <span>{translations.fullyAutomated}</span>
            <span className={styles.cardLabels}>
              {numberFormatter.format(
                journalCounts.predicted_reduced_journals_count
              )}
            </span>
            <Tag severity={"success"} className={styles.tag}>
              {`${percentage.predicted_reduced_journal_percentage}%`}
            </Tag>
          </div>
        </div>
        <Divider layout="vertical" />
        <div className={styles.summary}>
          <Badge severity={"danger"} />
          <div className={styles.summaryDetail}>
            <span>{translations.actionNeeded}</span>
            <span className={styles.cardLabels}>
              {numberFormatter.format(
                journalCounts.action_needed_reduced_journals_count
              )}
            </span>
            <Tag severity="danger" className={styles.tag}>
              {`${percentage.action_needed_reduced_journal_percentage}%`}
            </Tag>
          </div>
        </div>
        <Divider layout="vertical" />
        <div className={styles.summary}>
          <Badge severity={"info"} />
          <div className={styles.summaryDetail}>
            <span>{translations.correctedByAccountant}</span>
            <span className={styles.cardLabels}>
              {numberFormatter.format(
                journalCounts.corrected_reduced_journals_count
              )}
            </span>
            <Tag severity="info" className={styles.tag}>
              {`${percentage.corrected_reduced_journal_percentage}%`}
            </Tag>
          </div>
        </div>
        <Divider layout="vertical" />
        <div className={styles.summary}>
          <Badge severity={"success"} className={styles.historical} />
          <div className={styles.summaryDetail}>
            <span>{translations.historical}</span>
            <span className={styles.cardLabels}>
              {numberFormatter.format(
                journalCounts.historical_reduced_journals_count
              )}
            </span>
            <Tag
              severity={"success"}
              className={`${styles.tag} ${styles.historical}`}
            >
              {`${percentage.historical_reduced_journal_percentage}%`}
            </Tag>
          </div>
        </div>
      </div>
    </Card>
  );
}
