import { z } from "zod";
import { useMainApi } from "../../../../../main-api";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";

const AccountingClientsSchema = z.object({
    id: z.string(),
    name: z.string(),
    client_number: z.string().nullable(),
});

type AccountingClient = z.TypeOf<typeof AccountingClientsSchema>;

function useContextValue() {
    const mainApi = useMainApi();

    const [accountingClients, setAccountingClients] = useState<
        Readonly<{
            data?: AccountingClient[];
            loading?: boolean;
            error?: CommunicationError;
        }>
    >({});

    const [selectedAccountingClient, setSelectedAccountingClient] = useState<
        AccountingClient | undefined
    >();

    useEffect(() => {
        (async () => {
            setSelectedAccountingClient(undefined);
            setAccountingClients({ loading: true });

            const res = await mainApi.fetchJSON({
                method: "GET",
                path: `/accounting_clients`,
                schema: z.object({
                    status: z.literal(200),
                    body: z.array(AccountingClientsSchema),
                }),
            });

            if (res.error) {
                setAccountingClients({ error: res.error });
                return;
            }

            setAccountingClients({ data: res.response.body });
        })();
    }, [mainApi]);

    return useMemo(() => {
        return {
            selectedAccountingClient,
            setSelectedAccountingClient,
            accountingClients
        }
    }, [accountingClients, selectedAccountingClient])
}

export const AccountingClientContext = createContext<
    undefined | ReturnType<typeof useContextValue>
>(undefined);

export function AccountingClientProvider(props: {
    children: ReactNode;
}) {
    const value = useContextValue();

    return (
        <AccountingClientContext.Provider value={value}>
            {props.children}
        </AccountingClientContext.Provider>
    );
}