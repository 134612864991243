import { useContext, useCallback, useMemo, useRef } from "react";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { DEFAULT_SORT_COLUMN } from "./results/sorting/sorting-constants";
import styles from "./personal-accounts.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../../../../../communication-errors/communication-error-messages";
import { AccountingClient } from "../../../accountingClientLoader";
import { throwError } from "../../../../../../../../throw-error";
import { PersonalAccountsToolbar } from "./toolbar/personal-accounts-toolbar";
import { PersonalAccountsFiltersContextProvider } from "./toolbar/filters-context";
import {
  SortColumn,
  PersonalAccountsSortingContext,
  PersonalAccountsSortingContextProvider,
} from "./results/sorting/sorting-context";
import {
  PersonalAccount,
  PersonalAccountsResultsContext,
  PersonalAccountsResultsContextProvider,
} from "./results/results-context";
import { Menu } from "primereact/menu";
import { ContextMenu } from "./results/context-menu";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../../../react-data-grid";

const GERMAN_TRANSLATIONS = {
  accountNumber: "Kontonummer",
  accountName: "Kontoname",
  debitorOrCreditor: "D/K",
  debitorLabel: "D",
  creditorLabel: "K",
  transactions: "Transaktionen",
  loadingMoreRows: "Weitere Zeilen werden geladen...",
};

const ENGLISH_TRANSLATIONS = {
  accountNumber: "Account Number",
  accountName: "Account Name",
  bankAccount: "Bank Account",
  debitorOrCreditor: "D/C",
  debitorLabel: "D",
  creditorLabel: "C",
  transactions: "Transactions",
  loadingMoreRows: "Loading more rows...",
};

function _PersonalAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  const { results, setOffset } =
    useContext(PersonalAccountsResultsContext) || throwError();

  const selectedAccount = results.data?.rows.find(
    (account) => account.account_id === selectedAccountId
  );

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sorting = useContext(PersonalAccountsSortingContext) || throwError();

  const menuRef = useRef<Menu>(null);

  const toastRef = useRef<Toast>(null);

  const getRowKey = useCallback(
    (r: Partial<PersonalAccount>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn((sortColumn ?? DEFAULT_SORT_COLUMN) as SortColumn);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return [sorting.sortColumn];
  }, [sorting]);

  const columns: Column<PersonalAccount>[] = useMemo(() => {
    return [
      {
        key: "account_number",
        name: `${translations.accountNumber}`,
        editable: true,
        sortable: true,
      },
      {
        key: "account_name",
        name: `${translations.accountName}`,
        sortable: true,
        renderCell: (args) => {
          if (!args.row.account_name) {
            return <></>;
          }
          return <span>{args.row.account_name}</span>;
        },
      },
      {
        key: "debitor_or_creditor",
        name: `${translations.debitorOrCreditor}`,
        sortable: true,
        renderCell: (args) => {
          if (!args.row.debitor_or_creditor) {
            return <></>;
          }
          return (
            <span>
              {args.row.debitor_or_creditor === "creditor"
                ? `${translations.creditorLabel}`
                : `${translations.debitorLabel}`}
            </span>
          );
        },
      },
      {
        key: "total_transactions",
        name: "Transactions",
        sortable: true,
      },
    ];
  }, [translations]);

  return (
    <div className={styles.page}>
      <Toast ref={toastRef} />
      <ConfirmDialog />
      <PersonalAccountsToolbar />
      {(() => {
        const data = results.data;

        if (results.loading && !results.data) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }
        if (results.error) {
          return (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>
                {getCommunicationErrorMessage(results.error)}
              </p>
            </div>
          );
        }

        if (data && data.rows.length > 0) {
          return (
            <>
              <ReactDataGrid
                columns={columns}
                rows={results.data.rows}
                sortColumns={sortColumns}
                rowKeyGetter={getRowKey}
                onSortColumnsChange={onSortColumnsChange}
                onWheelAndBottom={() => {
                  setOffset(data.rows.length);
                }}
                className={`${styles.table}`}
                onCellClick={(args, event) => {
                  setSelectedAccountId(args.row.account_id);
                  menuRef.current?.toggle(event);
                }}
              />

              <ContextMenu
                selectedAccount={selectedAccount}
                accountingClientId={accountingClient.id}
                menuRef={menuRef}
                toastRef={toastRef}
              />

              {results.loading && (
                <div className={styles.loadingNextPageContainer}>
                  {translations.loadingMoreRows}
                </div>
              )}
            </>
          );
        }
      })()}
    </div>
  );
}

export default function PersonalAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <PersonalAccountsFiltersContextProvider accountingClient={accountingClient}>
      <PersonalAccountsSortingContextProvider>
        <PersonalAccountsResultsContextProvider
          accountingClient={accountingClient}
        >
          <_PersonalAccounts
            accountingClient={accountingClient}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
          />
        </PersonalAccountsResultsContextProvider>
      </PersonalAccountsSortingContextProvider>
    </PersonalAccountsFiltersContextProvider>
  );
}
