import { ProgressSpinner } from "primereact/progressspinner";
import { useContext } from "react";
import { throwError } from "../../../../../throw-error";
import { ResultsContext } from "./result-context";
import { Overview } from "./overview/overview";
import { Exports } from "./exports/exports";
import styles from "./results.module.scss";

export function Results() {
  const { results } = useContext(ResultsContext) || throwError();

  if (results.loading) {
    return <ProgressSpinner />;
  }

  if (results.error) {
    return <></>;
  }

  if (results.data) {
    return (
      <div className={styles.page}>
        <Overview topSectionData={results.data.top_section} />
        <Exports />
      </div>
    );
  }

  return <></>;
}
