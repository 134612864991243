import { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMainApi } from "../../../main-api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import styles from "./forgot-password.module.scss";
import { CommunicationError } from "../../../communication-errors/communication-errors";
import { getCommunicationErrorMessage } from "../../../communication-errors/communication-error-messages";
import { useNavigate } from "react-router-dom";
import { ACCOUNTANT_LOGIN_ROUTE } from "../login/routes";
import { throwError } from "../../../throw-error";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { AccountantAuthenticationStateContext } from "../../../accountants/authentication/authentication-state";

const GERMAN_TRANSLATIONS = {
  resetPasswordEmailSent:
    "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet. Bitte überprüfen Sie Ihren Posteingang und den Spam-Ordner.",
  userNotFound:
    "Benutzer nicht gefunden, bitte versuchen Sie eine andere E-Mail-Adresse.",
  back: "Zurück",
  backToLoginPage: "Zurück zur Anmeldeseite",
  sendResetLink: "Link zum Zurücksetzen senden",
  email: "E-Mail",
};

const ENGLISH_TRANSLATIONS = {
  resetPasswordEmailSent:
    "We've sent you an email with a link to reset your password. Please check your inbox and spam folder.",
  userNotFound: "User not found, please try a different email address.",
  back: "Back",
  backToLoginPage: "Back to login page",
  sendResetLink: "Send Reset Link",
  email: "Email",
};

const UserEmailSchema = z.object({
  email: z.string().min(1).trim().toLowerCase().email(),
});

type TUserEmailSchema = z.infer<typeof UserEmailSchema>;

export function AccountantUserForgotPassword() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();

  const { sessionState } =
    useContext(AccountantAuthenticationStateContext) || throwError();

  useEffect(() => {
    if (sessionState.data) {
      navigate("/accountant");
    }
  }, [sessionState, navigate]);

  const mainApi = useMainApi();

  const [state, setState] = useState<{
    loading?: boolean;
    error?: undefined | CommunicationError | "user-not-found";
    success?: boolean;
  }>({});

  const form = useForm<TUserEmailSchema>({
    resolver: zodResolver(UserEmailSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (formData: TUserEmailSchema) => {
    setState({ loading: true });

    const res = await mainApi.fetchJSON({
      path: `/accountant_userss/forgot-password`,
      method: "POST",
      body: {
        email: formData.email,
      },
      schema: z.union([
        z.object({
          status: z.literal(404),
        }),
        z.object({
          status: z.literal(200),
        }),
      ]),
    });

    setState({ loading: false });

    if (res.error) {
      setState({ error: res.error });
      return;
    }

    if (res.response.status === 404) {
      setState({ error: "user-not-found" });
      return;
    }

    setState({ success: true });
  };

  return (
    <main className={styles.page}>
      <Card className={`${styles.maxWidth480} ${styles.width100Percent}`}>
        {state.error &&
          (state.error === "user-not-found" ? (
            <div className={styles.marginBottomSpacer}>
              <Message
                severity="error"
                text={translations.userNotFound}
                className={styles.width100Percent}
              />
            </div>
          ) : (
            <div className={styles.marginBottomSpacer}>
              <Message
                severity="error"
                text={getCommunicationErrorMessage(state.error)}
                className={styles.width100Percent}
              />
            </div>
          ))}
        {state.success ? (
          <>
            <div className={styles.marginBottomSpacer}>
              <Message
                severity="success"
                text={translations.resetPasswordEmailSent}
                className={styles.width100Percent}
              />
            </div>
            <Button
              label={translations.backToLoginPage}
              onClick={() => navigate(ACCOUNTANT_LOGIN_ROUTE.getHref())}
              className={styles.width100Percent}
              outlined
            />
          </>
        ) : (
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formField}>
              <label htmlFor="email" className={styles.label}>
                {translations.email}
              </label>
              <Controller
                name="email"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames(styles.input, {
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {form.formState.errors.email && (
                <small className={styles.errorMessage}>
                  {form.formState.errors.email.message}
                </small>
              )}
            </div>
            <div className={styles.buttonsWrapper}>
              <Button
                icon="pi pi-angle-left"
                label={translations.back}
                onClick={() => navigate(ACCOUNTANT_LOGIN_ROUTE.getHref())}
                outlined
              />
              <Button
                label={translations.sendResetLink}
                onClick={form.handleSubmit(onSubmit)}
                disabled={state.loading}
                loading={state.loading}
              />
            </div>
          </form>
        )}
      </Card>
    </main>
  );
}
