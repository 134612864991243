export const EDIT_CLIENTS_ROUTES = {
  create: {
    getHref: () => `/accountant/accounting-clients/create`,
  },
  edit: {
    getHref: (params: { accountingClientId: string; saved?: boolean }) => {
      const urlSearchParams = new URLSearchParams();

      if (params.saved) {
        urlSearchParams.append("saved", "true");
      }

      return `/accountant/accounting-clients/${params.accountingClientId}/edit?${urlSearchParams.toString()}`;
    },
  },
};
