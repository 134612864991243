import {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../../../../../main-api";
import { CommunicationError } from "../../../../../../../../../communication-errors/communication-errors";
import { useAccountingClientId } from "../../../../../accounting-client-id";
import { QuickFiltersContext } from "../../quick-filters/quick-filters-context";
import { toDateString } from "../../../../../../../../../dates";
import { throwError } from "../../../../../../../../../throw-error";
import { BookingsPageBankAccountsContext } from "../../quick-filters/bank-accounts-context";

export const ExportSummarySchema = z.object({
  total: z.number(),
  predicted_without_issues: z.number(),
  corrected_by_accountant: z.number(),
  action_required: z.number(),
  excluded_historical_journals: z.number(),
});

export type ExportSummary = z.TypeOf<typeof ExportSummarySchema>;

function useContextValue() {
  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();

  const { quickFilters } = useContext(QuickFiltersContext) || throwError();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const selectedBankAccountId = selectedBankAccount?.account_id;

  const [results, setResults] = useState<
    Readonly<{
      data?: ExportSummary;
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  useEffect(() => {
    (async () => {
      if (!selectedBankAccountId) {
        return;
      }

      setResults({ loading: true });

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("account_id", selectedBankAccountId);

      for (const [key, value] of Object.entries(quickFilters)) {
        if (value instanceof Date) {
          urlSearchParams.append(key, toDateString(value));
        }
      }

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_dashboard/${accountingClientId}/bookings/bookings_to_be_exported_summary?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: ExportSummarySchema,
        }),
      });

      if (res.error) {
        setResults({ error: res.error });
        return;
      }

      setResults({ data: res.response.body });
    })();
  }, [accountingClientId, mainApi, quickFilters, selectedBankAccountId]);

  return useMemo(() => {
    return {
      accountingClientId,
      selectedBankAccountId,
      results,
    };
  }, [accountingClientId, results, selectedBankAccountId]);
}

export const ExportSummaryContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function ExportSummaryProvider(props: { children: ReactNode }) {
  const value = useContextValue();

  return (
    <ExportSummaryContext.Provider value={value}>
      {props.children}
    </ExportSummaryContext.Provider>
  );
}
