import { Booking } from "../../results/results-context";
import styles from "./issues.module.scss";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { useContext } from "react";
import { BookingsPageResourcesContext } from "../../page-resources";
import { throwError } from "../../../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
  issuesTitle: "Probleme",
  unreliablePredictionTitle: "Keine zuverlässige Vorhersage möglich",
  couldNotPredictTaxCodeTitle: "Kann Steuercodes nicht vorhersagen",
  multipleTaxCodesTitle: "Mehrere Steuercodes",
  multipleTaxCodesDescription:
    "Es gibt viele moegliche Steuercodes für diese Buchung:",
};

const ENGLISH_TRANSLATIONS = {
  issuesTitle: "Issues",
  unreliablePredictionTitle: "Unable to make a reliable prediction",
  couldNotPredictTaxCodeTitle: "Could not predict tax code",
  multipleTaxCodesTitle: "Multiple Tax Codes",
  multipleTaxCodesDescription:
    "There are many possible tax codes for this booking:",
};

export function Issues(props: { selectedBooking: Booking }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const pageResouces = useContext(BookingsPageResourcesContext) || throwError();

  return (
    <>
      <div>
        <h3 className={styles.marginBottom}>{translations.issuesTitle}</h3>
        <div className={styles.issues}>
          {props.selectedBooking.issues.map((issue) => {
            return (
              <div key={issue.id}>
                <h4 className={styles.marginBottom}>
                  {(() => {
                    if (issue.type === "COULD_NOT_PREDICT_TAX_CODE") {
                      return (
                        <span>{translations.couldNotPredictTaxCodeTitle}</span>
                      );
                    } else if (issue.type === "MULTIPLE_TAX_CODES") {
                      return <span>{translations.multipleTaxCodesTitle}</span>;
                    } else if (issue.type === "UNRELIABLE_PREDICTION") {
                      return (
                        <span>{translations.unreliablePredictionTitle}</span>
                      );
                    } else {
                      throw new Error();
                    }
                  })()}
                </h4>
                {(() => {
                  if (issue.type === "COULD_NOT_PREDICT_TAX_CODE") {
                    return <></>;
                  } else if (issue.type === "MULTIPLE_TAX_CODES") {
                    return (
                      <div>
                        <p className={styles.marginBottom}>
                          {translations.multipleTaxCodesDescription}:
                        </p>
                        <ul>
                          {issue.tax_code_ids.map((taxCodeId) => {
                            const taxCode =
                              pageResouces.tax_codes.find(
                                (taxCode) => taxCode.id === taxCodeId
                              ) || throwError();

                            return <li key={taxCodeId}>{taxCode.tax_code}</li>;
                          })}
                        </ul>
                      </div>
                    );
                  } else if (issue.type === "UNRELIABLE_PREDICTION") {
                    return <p>{issue.description}</p>;
                  } else {
                    throw new Error();
                  }
                })()}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
