import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { useRef, useState, useContext } from "react";
import { Toast } from "primereact/toast";
import { throwError } from "../../../../throw-error";
import { useMainApi } from "../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../communication-errors/communication-errors";
import { z } from "zod";
import { AccountingClientsContext } from "../../menu/accounting-clients-context";

const ENGLISH_TRANSLATIONS = {
  createClientWithGDPDUFile: "Create with GDPDU File",
  clientCreatedSuccessfully: "Client created successfully",
  clientNumberIsTaken: "A client with the same client number already exists",
  vatIdIsTaken: "A client with the same VAT ID already exists",
};

const GERMAN_TRANSLATIONS = {
  createClientWithGDPDUFile: "Mit GDPDU-Datei erstellen",
  clientCreatedSuccessfully: "Kunde erfolgreich erstellt",
  clientNumberIsTaken:
    "Ein Kunde mit dem gleichen Kundennummer existiert bereits",
  vatIdIsTaken:
    "Ein Kunde mit dem gleichen Umsatzsteuer-Identifikationsnummer existiert bereits",
};

export function CreateClientWithGdpduFile(props: { fetchClients: () => void }) {
  const mainApi = useMainApi();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const toastRef = useRef<Toast | null>(null);

  const [state, setState] = useState<{ loading?: boolean }>({});

  const { refreshAccountingClients } =
    useContext(AccountingClientsContext) || throwError();

  return (
    <>
      <Toast ref={toastRef} />
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={async (e) => {
          const file = e.target.files?.[0] ?? throwError();

          setState({ loading: true });

          const formData = new FormData();
          formData.append("file", file);

          const res = await mainApi.fetch(
            `/accounting_clients/create/gdpdu`,
            [200, 409],
            {
              method: "POST",
              body: formData,
            }
          );

          inputRef.current!.value = "";

          setState({ loading: false });

          if (res.error) {
            toastRef.current!.show({
              severity: "error",
              summary: getCommunicationErrorTitle(res.error),
              detail: getCommunicationErrorMessage(res.error),
            });

            return;
          }

          if (!res.response.ok) {
            const json = await res.response.json();

            const bodyValidation = z
              .object({
                detail: z.union([
                  z.literal("client-number-is-taken"),
                  z.literal("vat-id-is-taken"),
                ]),
              })

              .safeParse(json);

            if (!bodyValidation.success) {
              toastRef.current!.show({
                severity: "error",
                summary: getCommunicationErrorTitle(
                  CommunicationError.UnexpectedResponse
                ),
                detail: getCommunicationErrorMessage(
                  CommunicationError.UnexpectedResponse
                ),
              });
              return;
            }

            const body = bodyValidation.data;

            if (body.detail === "client-number-is-taken") {
              toastRef.current!.show({
                severity: "warn",
                summary: translations.clientNumberIsTaken,
                life: 10 * 1000,
              });
            } else if (body.detail === "vat-id-is-taken") {
              toastRef.current!.show({
                severity: "warn",
                summary: translations.vatIdIsTaken,
                life: 10 * 1000,
              });
            } else {
              throw new Error();
            }

            return;
          }

          toastRef.current!.show({
            severity: "success",
            summary: translations.clientCreatedSuccessfully,
          });

          props.fetchClients();
          refreshAccountingClients();
        }}
      />
      <Button
        icon="pi pi-upload"
        label={translations.createClientWithGDPDUFile}
        disabled={state.loading}
        loading={state.loading}
        onClick={() => {
          inputRef.current!.click();
        }}
      />
    </>
  );
}
