import { useState } from "react";
import { AccountingClient } from "../accountingClientLoader";
import { Accounts } from "./accounts/accounts";

function _AccountsOverview({
  accountingClient,
  activeIndex,
  setActiveIndex,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <Accounts
      accountingClient={accountingClient}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      selectedAccountId={selectedAccountId}
      setSelectedAccountId={setSelectedAccountId}
    />
  );
}

export default function AccountsOverview(props: {
  accountingClient: AccountingClient;
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >();

  return (
    <_AccountsOverview
      accountingClient={props.accountingClient}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      selectedAccountId={selectedAccountId}
      setSelectedAccountId={setSelectedAccountId}
    />
  );
}
