import { ReactNode, createContext, useState } from "react";

export type SortColumn = {
  columnKey: "transaction_date" | "amount" | "counter_account";
  direction: "ASC" | "DESC";
};

function useContextValue() {
  const [sortColumn, setSortColumn] = useState<SortColumn | undefined>();

  return {
    sortColumn,
    setSortColumn,
  };
}

export const SortingContext = createContext<
  ReturnType<typeof useContextValue> | undefined
>(undefined);

export function SortingContextProvider({ children }: { children: ReactNode }) {
  const value = useContextValue();

  return (
    <SortingContext.Provider value={value}>{children}</SortingContext.Provider>
  );
}
