import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import styles from "./export-success-message.module.scss";
import { useMainApi } from "../../../../../../../../../../main-api";
import { getCommunicationErrorMessage } from "../../../../../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  successful: "Erfolgreich!",
  bookingsExported: "Buchungen erfolgreich exportiert!",
  xmlTemplateNeeded: "XML-Vorlage für den Import in Edrewe benötigt.",
  xmlImportInstruction:
    "Um Buchungen in Edrewe zu importieren, verwenden Sie bitte unsere XML-Vorlage, ",
  xmlDownloadHere: "die Sie hier herunterladen können.",
};

const ENGLISH_TRANSLATIONS = {
  successful: "Successful!",
  bookingsExported: "Bookings exported successfully!",
  xmlTemplateNeeded: "XML template needed for import into Edrewe.",
  xmlImportInstruction:
    "XML template needed for import into Edrewe. To import bookings into Edrewe, please use our XML template, ",
  xmlDownloadHere: "which you can download here.",
};

export function ExportSuccessMessageDialog({
  visible,
  onHide,
}: {
  visible: boolean;
  onHide: () => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const toastRef = useRef<Toast>(null);

  const onClick = async () => {
    const res = await mainApi.fetch(
      `/accounting_dashboard/bookings/export/bookings_xml_template`,
      [200],
      { method: "GET" }
    );

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }

    const blob = await res.response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "bookings_xml_template.csv";
    a.click();

    window.URL.revokeObjectURL(url);
  };
  return (
    <>
      <Toast ref={toastRef} />
      <Dialog visible={visible} style={{ width: "45vw" }} onHide={onHide}>
        <div className={styles.dialogContainer}>
          <h1>{translations.successful}</h1>
          <span>{translations.bookingsExported}</span>
          <Message
            className={styles.width100Percent}
            severity="warn"
            content={
              <div className={styles.displayFlexGapDoubleSpacer}>
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "2rem" }}
                ></i>
                <div className={styles.flexDirectionColumn}>
                  <strong>{translations.xmlTemplateNeeded}</strong>
                  <br />
                  {translations.xmlImportInstruction}
                  <span className={"link-inherit"} onClick={onClick}>
                    {translations.xmlDownloadHere}
                  </span>
                </div>
              </div>
            }
          />
        </div>
      </Dialog>
    </>
  );
}
