import { QuickFilters } from "./quick-filters/quick-filters";
import { Filters } from "./filters/filters";
import styles from "../top-section.module.scss";
import { ExportCsvDialog } from "./export-csv/export-csv-dialog";
import { BankAccountsDropdown } from "./quick-filters/bank-accounts-dropdown";

export function NavBar() {
  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.navbarItems}>
          <div className={styles.buttonBar}>
            <BankAccountsDropdown className={styles.dropdown} />
          </div>
          <QuickFilters />
        </div>
        <div className={styles.navbarItems}>
          <div className={styles.buttonBar}>
            <div className={styles.buttonGroup}>
              <ExportCsvDialog />
              <Filters />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
