import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { QuickFilters } from "../quick-filters/quick-filters";
import styles from "./export-csv-dialog.module.scss";
import {
  ExportSummaryProvider,
  ExportSummaryContext,
} from "./results/export-summary-results-context";
import { throwError } from "../../../../../../../../throw-error";
import {
  Pie,
  PieChart,
  Legend,
  Cell,
  PieLabelRenderProps,
  ResponsiveContainer,
} from "recharts";
import { ExportCsvButton } from "./export-flow/export-button/export-csv-button";
import { ExportSuccessMessageDialog } from "./export-flow/end/export-succcess-message";
import { Message } from "primereact/message";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { Props } from "recharts/types/component/DefaultLegendContent";

const GERMAN_TRANSLATIONS = {
  exportCsv: "CSV exportieren",
  exportBookings: "Buchungen exportieren",
  exportInstruction:
    "Buchungen im ausgewählten Datumsbereich werden exportiert.",
  excludedHistoricalsMessage:
    "historische Buchungen werden nicht exportiert, um Duplikate zu vermeiden.",
  chargingInfo:
    "Zusätzliche Gebühren können auf Exporte angewendet werden; Für weitere Informationen besuchen Sie bitte",
  totalBookings: "Gesamtbuchungen",
  fullyAutomated: "Vollständig automatisiert",
  actionNeeded: "Handlung erforderlich",
  correctedByAccountant: "Vom Buchhalter korrigiert",
};

const ENGLISH_TRANSLATIONS = {
  exportCsv: "Export CSV",
  exportBookings: "Export Bookings",
  exportInstruction:
    "Bookings within the selected date range will be exported.",
  excludedHistoricalsMessage:
    "historical bookings will not be exported to avoid duplicates.",
  chargingInfo:
    "Additional charges may apply on exports; for more information, please visit",
  totalBookings: "Total Bookings",
  fullyAutomated: "Fully Automated",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
};

function _ExportCsvDialog() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [exportDialogVisible, setExportDialogVisible] = useState(false);
  const [messageDialogVisible, setMessageDialogVisible] = useState(false);

  const onHideExportDialog = () => {
    setExportDialogVisible(false);
  };

  const onHideMessageDialog = () => {
    setMessageDialogVisible(false);
  };

  const showMessageDialog = () => {
    setExportDialogVisible(false);
    setMessageDialogVisible(true);
  };

  const { results } = useContext(ExportSummaryContext) || throwError();

  const navigate = useNavigate();

  const chartData = results
    ? [
        {
          name: translations.fullyAutomated,
          value: results.data?.predicted_without_issues,
          color: styles.successColor,
          textColor: styles.textSuccessColor,
        },
        {
          name: translations.actionNeeded,
          value: results.data?.action_required,
          color: styles.errorColor,
          textColor: styles.textErrorColor,
        },
        {
          name: translations.correctedByAccountant,
          value: results.data?.corrected_by_accountant,
          color: styles.infoColor,
          textColor: styles.textInfoColor,
        },
      ]
    : [];

  const renderLegend = (props: Props) => {
    const { payload } = props;
    if (!payload) return null;
    return (
      <>
        <div className={styles.totalBookings}>
          <div>{translations.totalBookings}</div>
          <div className={styles.fontSize2Rem}>{results.data?.total}</div>
        </div>

        {chartData.map((data, index) => (
          <div key={`item-${index}`} className={styles.chartDataItem}>
            <div>{data.name}</div>
            <div
              className={styles.fontSize1_5Rem}
              style={{ color: data.textColor }}
            >
              {data.value}
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: PieLabelRenderProps) => {
    const RADIAN = Math.PI / 180;
    const radius =
      Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
    const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
    const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${((percent ?? 0) * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div>
      <Button
        label={translations.exportCsv}
        onClick={() => setExportDialogVisible(true)}
      />
      <Dialog
        style={{ width: "45vw" }}
        header={translations.exportBookings}
        visible={exportDialogVisible}
        onHide={onHideExportDialog}
      >
        <div className={styles.marginSpacer}>
          <div className={styles.marginBottomDoubleSpacer}>
            {translations.exportInstruction}
          </div>
          <div
            className={`${styles.menuItems} ${styles.marginBottomDoubleSpacer}`}
          >
            <QuickFilters disabled={true} />
            <ExportCsvButton onExport={showMessageDialog} />
          </div>

          {(() => {
            const data = results.data;

            if (!data) {
              return <></>;
            }

            return (
              <>
                <Message
                  className={styles.width100Percent}
                  severity="warn"
                  text={
                    <span>
                      <b>{results.data.excluded_historical_journals}</b>{" "}
                      {translations.excludedHistoricalsMessage}
                    </span>
                  }
                />
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={chartData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      labelLine={false}
                      label={renderCustomizedLabel}
                    >
                      {chartData.map((data, index) => (
                        <Cell key={`cell-${index}`} fill={data.color} />
                      ))}
                    </Pie>
                    <Legend
                      content={(props: Props) => renderLegend(props)}
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                    />
                  </PieChart>
                </ResponsiveContainer>
                <Message
                  className={styles.width100Percent}
                  severity="info"
                  text={
                    <>
                      {translations.chargingInfo}{" "}
                      <span
                        className={"link-inherit"}
                        onClick={() => navigate("/accountant")}
                      >
                        Metrics.
                      </span>
                    </>
                  }
                />
              </>
            );
          })()}
        </div>
      </Dialog>
      <ExportSuccessMessageDialog
        visible={messageDialogVisible}
        onHide={onHideMessageDialog}
      />
    </div>
  );
}

export function ExportCsvDialog() {
  return (
    <ExportSummaryProvider>
      <_ExportCsvDialog />
    </ExportSummaryProvider>
  );
}
